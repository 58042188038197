import { ACTIONS, MUTATIONS } from './entries.constants';
import tenantEntriesService from './entries.service';

const payment = {
  state: {
    loading: false,
    tenants: [],
    detailTenant: {}
  },
  actions: {
    [ACTIONS.MAIL_PERSON]({ commit }, data) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.mailPerson(data)
          .then((result) => {
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
      });
    },
    [ACTIONS.MAIL_TENANTS_SELECTION]({ commit }, data) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.mailTenantsSelection(data)
          .then((result) => {
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
      });
    },
    [ACTIONS.MAIL_ENTRIES_SELECTION]({ commit }, data) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.mailEntriesSelection(data)
          .then((result) => {
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
      });
    },
    [ACTIONS.MAIL_ALL]({ commit }) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise(() => {
        tenantEntriesService.mailAll().finally(() => {
          commit(MUTATIONS.SET_IS_LOADING, false);
        });
      });
    },
    [ACTIONS.GET_ALL_TENANTS]({ commit }, filter) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.getTenants(filter)
          .then((result) => {
            commit(MUTATIONS.SET_ALL_TENANTS, result.data.tenants);
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
          .finally(() => {
            commit(MUTATIONS.SET_IS_LOADING, false);
          });
      });
    },
    [ACTIONS.GET_DETAIL_TENANT]({ commit }, id) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.getTenantDetail(id)
          .then((result) => {
            commit(MUTATIONS.SET_DETAIL_TENANT, result.data);
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
          .finally(() => {
            commit(MUTATIONS.SET_IS_LOADING, false);
          });
      });
    }, [ACTIONS.UPDATE_TENANT]({ commit }, tenant) {
      commit(MUTATIONS.SET_IS_LOADING, true);
      return new Promise((resolve, reject) => {
        tenantEntriesService.updateTenant(tenant)
          .then((result) => {
            commit(MUTATIONS.SET_DETAIL_TENANT, result.data);
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
          .finally(() => {
            commit(MUTATIONS.SET_IS_LOADING, false);
          });
      });
    }, [ACTIONS.UPLOAD_FILE]({ commit }, file) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.uploadData(file)
          .then((result) => {
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.DOWNLOAD_RENTAL_DIARY]({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.downloadRentalDiary(params).then(
          result => resolve(result.data),
          error => reject(error)
        );
      });
    },
    [ACTIONS.DOWNLOAD_ENTRIES]({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.downloadEntriesList(params).then(
          result => resolve(result.data),
          error => reject(error)
        );
      });
    },
    [ACTIONS.SET_PASSPORT_CHECK]({ commit, dispatch }, tenant) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.updateTenantPassportCheck(tenant)
          .then((result) => {
            dispatch(ACTIONS.GET_ALL_TENANTS);
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.SET_PAYMENT_CHECK]({ commit, dispatch }, tenant) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.updateTenantPaymentCheck(tenant)
          .then((result) => {
            dispatch(ACTIONS.GET_ALL_TENANTS);
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.DELETE_TENANT]({ commit, dispatch }, tenant) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.deleteTenant(tenant)
          .then((result) => {
            dispatch(ACTIONS.GET_ALL_TENANTS);
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.UPLOAD_TENANT]({ commit }, file) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.uploadTenant(file)
          .then((result) => {
            resolve(result.data);
          }, (error) => {
            reject(error);
          })
      });
    },
    [ACTIONS.UPDATE_REMARKS]({ commit, dispatch }, {
      unitId,
      remark
    }) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.updateContractRemarks(unitId, remark)
          .then((result) => {
            dispatch(ACTIONS.GET_ALL_TENANTS);
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.REMOVE_REMARK]({ commit, dispatch }, {
      unitId,
      remarkId,
    }) {
      return new Promise((resolve, reject) => {
        tenantEntriesService.removeContractRemarks(unitId, remarkId)
          .then((result) => {
            dispatch(ACTIONS.GET_ALL_TENANTS);
            resolve(result.data);
          }, (error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    [MUTATIONS.SET_ALL_TENANTS](state, tenants) {
      state.tenants = tenants;
    },
    [MUTATIONS.SET_DETAIL_TENANT](state, tenant) {
      state.detailTenant = tenant;
    },
    [MUTATIONS.SET_IS_LOADING](state, loadingState) {
      state.loading = loadingState;
    },
  },
};


export default payment;
