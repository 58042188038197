export const MODULE_NAME = 'permissions';

export const ACTIONS = {
  GET_ROLES: `${MODULE_NAME}/GET_ROLES`,
};


export const MUTATIONS = {
	SET_ALL_ROLES: `${MODULE_NAME}/SET_ALL_ROLES`,
	SET_ALL_RESETS: `${MODULE_NAME}/SET_ALL_RESETS`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
