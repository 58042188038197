import Vue from 'vue'
import moment from 'moment';

Vue.filter('formatDate', (value) => {
  if (value === '-') {
    return '-'
  }

  if (value && value.length === 10) {
    value = moment(`${value}`, ["DD-MM-YYYY", "MM-DD-YYYY", "YYYY-MM-DD"]).toISOString(true);
  } 

  if (value) {
    return moment(String(value), "YYYY-MM-DD").format('DD/MM/YYYY');
  } 

  return value;
});

Vue.filter('formatDateFull', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMM');
  }
  return value;
});

Vue.filter('formatDateLogs', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY - HH:mm:ss');
  }
  return value;
});
