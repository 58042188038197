<template>
	<v-card>
		<v-card-title>
			<h3 v-if="editItem">Contract aanpassen</h3>
			<h3 v-else-if="!extension">Contract aanmaken</h3>
			<h3 v-else>Contract verlengen</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex class="pa-3">
					<v-form ref="form">
						<v-row>
							<v-col cols="12" sm="12"> <h3 class="black--text">Contract gegevens</h3> </v-col>
							<v-col
								cols="12"
								lg="6"
								class="py-0"
							>
								<v-menu
									ref="menu1"
									v-model="menu1"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											:disabled="editItem"
											v-model="dateFormatted"
											label="Start datum"
											persistent-hint
											prepend-icon="mdi-calendar"
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="date"
										no-title
										@input="menu1 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col
								cols="12"
								lg="6"
								class="py-0"
							>
								<v-menu
									ref="menu2"
									v-model="menu2"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="endDateFormatted"
											label="Eind datum"
											persistent-hint
											prepend-icon="mdi-calendar"
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="endDate"
										:picker-date.sync="defaultValue"
										:min="date"
										no-title
										@input="menu2 = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" sm="11" class="py-0">
								<v-autocomplete
									v-model="tenant"
									:label="'Selecteer een huurder'"
									class="pt-0"
									:items="tenantNames"
									item-text="fullName"
          				item-value="_id"
									:rules="[() => !!tenant || 'Dit veld is verplicht']"
									:disabled='extension'
								></v-autocomplete>
							</v-col>

							<v-col cols="12" sm="1" class="py-0" v-if="!extension && !editItem">
								<v-icon class="mt-3" color="black" @click="showCreateTenant = true">
									mdi-account-plus
								</v-icon>
							</v-col>

							<v-card v-if="showCreateTenant" class="mb-10">
								<v-card-text>
									<v-flex class="pa-3">
										<v-form ref="form">
											<v-row>
												<h3 class="ml-2 my-3">Huurder aanmaken</h3>
												<v-col cols="12" sm="12" class="py-0">
													<v-text-field
														v-model="tenantID"
														label="Tenant Id (from omnibox)*"
														clearable
														class="pt-0"
														:rules="[() => !!tenantID || 'Dit veld is verplicht']"
													></v-text-field>
												</v-col>

												<v-col
													cols="12"
													lg="12"
													class="my-n6"
												>
													<v-menu
														ref="menu3"
														v-model="menu3"
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																v-model="dateOfBirthFormatted"
																label="Geboortedatum"
																persistent-hint
																prepend-icon="mdi-calendar"
																v-bind="attrs"
																@blur="date = parseDate(dateOfBirthFormatted)"
																v-on="on"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="dateOfBirth"
															no-title
															@input="menu3 = false"
														></v-date-picker>
													</v-menu>
												</v-col>

												<v-col cols="12" sm="6" class="py-0">
													<v-text-field
														v-model="firstName"
														label="Voornaam*"
														clearable
														class="pt-0"
														:rules="[() => !!firstName || 'Dit veld is verplicht']"
													></v-text-field>
												</v-col>

												<v-col cols="12" sm="6" class="py-0">
													<v-text-field
														v-model="lastName"
														label="Naam*"
														clearable
														class="pt-0"
														:rules="[() => !!lastName || 'Dit veld is verplicht']"
													></v-text-field>
												</v-col>

												<v-col cols="12" sm="6" class="py-0">
													<v-text-field
														v-model="email"
														label="E-mail*"
														clearable
														class="pt-0"
														:rules="emailRules"
													></v-text-field>
												</v-col>

												<v-col cols="12" sm="6" class="py-0">
													<v-text-field
														v-model="emailParent"
														label="E-mail van een ouder"
														clearable
														class="pt-0"
													></v-text-field>
												</v-col>

												<v-col cols="12" sm="6" class="py-0">
													<v-select
														:items="languages"
														item-text="name"
          									item-value="_id"
														v-model="language"
														label="Selecteer een taal"
														clearable
														class="pt-0"
														:rules="[() => !!language || 'Dit veld is verplicht']"
													></v-select>
												</v-col>

												<v-col cols="12" sm="12" class="py-0 mb-3 d-flex justify-end">
													<v-btn color="primary mr-2" @click="showCreateTenant = false" class="mb-2">Annuleren</v-btn>
													<v-btn :disabled='!isTenantComplete' @click="createTenant" class="mb-2">Opslaan</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-flex>
								</v-card-text>
							</v-card>

							<v-col cols="12" sm="6" class="py-0">
								<v-select
									v-model="projectName"
									:label="projectNameLabel || 'Project naam*'"
									class="pt-0"
									:items="projectNames"
									item-text="name"
          				item-value="_id"
									@change="filteredProjectNames"
									:rules="[() => !!projectName || 'Dit veld is verplicht']"
									:disabled='extension'
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-autocomplete
									v-model="unitName"
									:label="unitNameLabel || 'Unit naam*'"
									clearable
									class="pt-0"
									:items="unitNames"
									item-text="name"
          				item-value="_id"
									:rules="[() => !!unitNames || 'Dit veld is verplicht']"
									:disabled='unitNameDisabled'
                  @change="getUnitPrice"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									v-model="rentPrice"
									label="Huurprijs"
									clearable
									class="pt-0"
									:rules="[() => !!rentPrice || 'Dit veld is verplicht']"
								></v-text-field>
							</v-col>

							<v-col cols="12" sm="12" class="py-0" v-if="extension && !editItem">
								Eenmalige betalingen
								<div class="d-flex">
								<v-switch
									class="mr-3"
									v-model="switchRent"
									flat
									label="Huur"
                  :disabled="switchRent==null"
								></v-switch>
								<v-switch
									class="mr-3"
									v-model="switchDeposit"
									flat
									label="Waarborg"
                  :disabled="switchDeposit==null"
								></v-switch>
								<v-switch
									class="mr-3"
									v-model="switchEGWI"
									flat
									label="EGWI"
                  :disabled="switchEGWI==null"
								></v-switch>
								<v-switch
									class="mr-3"
									v-model="switchInventory"
									flat
									label="Plaatsbeschrijving"
                  :disabled="switchInventory==null"
								></v-switch>
								<v-switch
									class="mr-3"
									v-model="switchStudenttax"
									label="Taks"
                  flat
          				:disabled="switchStudenttax==null"
								></v-switch>
								</div>
							</v-col>

							<v-col cols="12" sm="12" class="py-0" v-if="!editItem">
								<v-textarea
									auto-grow
									rows="5"
          				row-height="22"
									v-model="contractComments"
									label="Opmerkingen"
									clearable
									class="pt-0"
								></v-textarea>
							</v-col>

							<!-- <v-col cols="12" sm="12" class="py-0 d-flex flex-row" v-if="editItem">
								<div class="d-flex flex-row">
									<p class="mr-3">Active mandate</p>
									<v-radio-group
										v-model="activeMandate"
										activeMandate
										class="pt-0 mt-0"
									>
										<v-radio
											v-if="activeMandate"
											label="Yes"
											:value="true"
											:disabled="true"
										></v-radio>
										<v-radio
											v-if="!activeMandate"
											label="No"
											:value="false"
											:disabled="true"
										></v-radio>
									</v-radio-group>
								</div>

								<div v-if="editItem && activeMandate !== false">
									<div class="d-flex flex-row">
										<p class="mr-3 ml-5">from</p>
										<v-text-field
											class="pt-0 mt-n1"
											:value="dateMandate"
											disabled
										></v-text-field>
									</div>
								</div>
							</v-col> -->

							<!-- <v-col cols="12" sm="12" class="py-0" v-if="editItem && item.fees.rent === 'PAID' && !item.eCurringSubscriptions"> -->

							<v-col cols="12" sm="12" class="py-0" v-if="editItem && hasPaidEcurringFees && (!item.eCurringSubscriptions || item.eCurringSubscriptions.length == 0)">
								<p class="red--text">Om het eCurring bankaccount aan te passen zal je dit manueel moeten doen</p>
							</v-col>
							<!--  v-if="extension && !editItem" -->
							
							<v-col col="12" sm="6"  class="py-0"  >
								<v-select v-if="extension && editItem" 
									v-model="registrationDocument"
									:label="'Status Inschrijvings Document'"
									
									:items="regDocumentStatuses"
									item-text="name"
          				item-value="_id"
								></v-select>
							</v-col>

							<!-- <v-col cols="12" sm="12"> -->
								<v-col cols="12" sm="6"  class="py-0"  v-if="editItem && !hasPaidEcurringFees && (!item.eCurringSubscriptions || item.eCurringSubscriptions.length == 0)">
									<v-switch
									v-model="ecurringDateSwitch"
									label="Startdatum eCurring overschrijven"
								></v-switch>
								</v-col>
								<v-col cols="12" sm="6" class="pt-6 py-0" v-if="ecurringDateSwitch && editItem">
													<v-menu
														ref="menu5"
														v-model="menu5"
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																v-model="ecurringDateFormatted"
																label="Nieuwe startdatum eCurring"
																persistent-hint
																prepend-icon="mdi-calendar"
																v-bind="attrs"
																v-on="on"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="ecurringDate"
															no-title
															@input="menu5 = false"
														></v-date-picker>
													</v-menu>
												</v-col>
								<v-col cols="12" sm="6" class="pt-6 py-0" >
									<v-text-field v-if="newContractStartDate && editItem && !ecurringDateSwitch"
																v-model="newContractStartDate"
																label="Nieuwe startdatum eCurring"
																persistent-hint
																prepend-icon="mdi-calendar"
																disabled
															></v-text-field>
								</v-col>
								<!-- <v-col col="12" sm="6"  class="py-0">
									<v-select
									v-model="registrationDocument"
									:label="'Status Registratie Document'"
									
									:items="regDocumentStatuses"
									item-text="name"
          				item-value="_id"
								></v-select> -->
								<!-- </v-col> -->
													

								<!-- <v-card v-if="ecurringDateSwitch && editItem">
								<v-card-text>
									<v-flex class="pa-3">
										<v-form ref="form">
											<v-row>
												<v-col cols="12" sm="4" class="pt-6 py-0">
													<v-menu
														ref="menu5"
														v-model="menu5"
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																v-model="ecurringDateFormatted"
																label="Nieuwe startdatum eCurring"
																persistent-hint
																prepend-icon="mdi-calendar"
																v-bind="attrs"
																v-on="on"
															></v-text-field>
														</template>
														<v-date-picker
															v-model="ecurringDate"
															no-title
															@input="menu5 = false"
														></v-date-picker>
													</v-menu>
												</v-col>
												<v-col cols="12" sm="8"></v-col>
											</v-row>
										</v-form>
									</v-flex>
								</v-card-text>
							</v-card> -->
					
							<!-- Ecurring reset, if filled in this will overwrite the current Ecurring data for this tenant -->
							<v-col cols="12" sm="12" class="py-0" v-if="editItem && item.eCurringSubscriptions && item.eCurringSubscriptions.length>0">
								<v-switch
									v-model="resetEcurringSwitch"
									label="eCurring reset"
								></v-switch>
							</v-col>
							<v-card v-if="resetEcurringSwitch && editItem">
								<v-card-text>
									<v-flex class="pa-3">
										<v-form ref="form">
											<v-row v-for="eCurringItem in overrideEcurringSubscriptions" :key="eCurringItem._id">
												<v-col cols="12" sm="12" class="mb-n4 d-flex">
													<div>
														<v-switch
															v-model="eCurringItem.switch"
															inset
															class="mt-0 mr-2"
														></v-switch>
													</div>
													<div>
														<h2>{{ eCurringItem.paymentType }}</h2>
														<p>Mandaat werd <span class="font-weight-bold" v-once>{{ eCurringItem.startDate | formatDate }}</span> gestart. </p>
													</div>
												</v-col>

												<v-col cols="12" sm="3" class="pt-0 py-0" v-if="eCurringItem.switch">
													<v-menu
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															:value="computedDateFormattedMomentjs(eCurringItem.startDate)"
															label="Nieuwe eCurring startdatum"
															readonly
															v-bind="attrs"
															v-on="on"
														></v-text-field>
													</template>
													<v-date-picker
														v-model="eCurringItem.startDate"
													></v-date-picker>
													</v-menu>
												</v-col>
												<!-- <v-col cols="12" sm="8"></v-col> -->
												<v-col cols="12" sm="3" md="3" class="pt-3" v-if="eCurringItem.switch">
													<v-text-field
														v-once
														v-model="eCurringItem.bankAccount"
														label="Bankrekeningnummer"
														clearable
														:rules="bankAccountRules"
														class="pt-0"
													></v-text-field>
												</v-col>
												<v-col cols="12" sm="3" md="3" class="pt-3" v-if="eCurringItem.switch">
													<v-text-field
														v-once
														v-model="eCurringItem.firstName"
														label="Voornaam"
														clearable
														class="pt-0"
													></v-text-field>
												</v-col>
												<v-col cols="12" sm="3" md="3" class="pt-3" v-if="eCurringItem.switch">
													<v-text-field
														v-once
														v-model="eCurringItem.lastName"
														label="Achternaam"
														clearable
														class="pt-0"
													></v-text-field>
												</v-col>
											</v-row>
										</v-form>
									</v-flex>
								</v-card-text>
							</v-card>

							<v-col v-if="this.editItem" cols="12" sm="12" class="mt-4 py-0 text-left">
                <h3 class="black--text">Extra betalingen</h3>
								<create-payment @save="getOneTimePayments" :item="item"></create-payment>
							</v-col>

						</v-row>
					</v-form>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="mr-2">
			<v-spacer></v-spacer>
      			<v-btn
					color="blue darken-1"
					text
					@click="$emit('close',{hasChanges,contractId })"
			>
				Annuleren
			</v-btn>
			<v-btn v-if="editContract" color="blue darken-1" text @click="saveClicked" :disabled='!isComplete' v-bind:loading='isLoading'><span v-if="editItem">Contract aanpassen</span><span v-else-if="!extension">Contract aanmaken</span><span v-else>Contract verlengen</span></v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from '../../../../services/backendApi';
	import {validateIbanChecksum} from '../../../../utils/iban.js';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
	import { mapState, mapActions, mapGetters } from 'vuex'
	import moment from 'moment';
	import { fromat, parseISO } from 'date-fns';

	import createPayment from './create-payment';

	export default {
		name: "create-contract-dialog",
		components: { 'create-payment': createPayment },
		props: {
			extension: {
				type: Boolean,
			},
			item: {
				type: Object,
			},
			editItem: {
				type: Boolean,
			},
      isLoading: {
        type:Boolean,
        default:false
      },
			canEdit: {
				type: String,
			}
		},
		data: vm => ({
			defaultValue: undefined,
			initialData: [],
      hasChanges:false,
			extraPayments: [],
			overrideEcurringSubscriptions: [],
			newContractStartDate: null,
			newSubscriptions: [],
			contractId: '',
			tenantLabel: '',
			projectNameLabel: '',
			unitNameLabel: '',
			switchRent: null,
			switchDeposit: null,
			switchEGWI: null,
			switchInventory: null,
			switchStudenttax: null,
			dateOfBirth: null,
			showCreateTenant: false,
			tenantID: '',
			firstName: '',
			lastName: '',
			email: '',
			emailParent: '',
			status: true,
			date: null,
			endDate: null,
			tenant: null,
			projectName: null,
			registrationDocument:'',
			unitName: null,
			rentPrice: '',
			contractComments: '',
			errorMessages: '',
			formHasErrors: false,
			projectNames: [],
			unitNames: [],
			tenantNames: [],
			regDocumentStatuses:[],
			unitNameDisabled: true,
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
			menu1: false,
      endDateFormatted: null,
			menu2: false,
			dateOfBirth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateOfBirthFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
			menu3: false,
			resetEcurringDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      resetEcurringDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
			menu4: false,
			ecurringDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      ecurringDateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
		menu5: false,
			basicUrl: process.env.VUE_APP_API_URL,
			emailRules: [
				v => !!v || "E-mailadres is verplicht",
				v => /.+@.+/.test(v) || "Geen geldig e-mailadres",
			],
			language: null,
			languages: [],

			ecurringID: null,
			resetEcurringSwitch: false,
			ecurringBankaccount: null,
			ecurringFirstName: null,
			ecurringLastName: null,
			bankAccountRules: [
				(v) => !!v || "Gelieve dit veld in te vullen",
				(v) => validateIbanChecksum(v) || "Gelieve een geldig nummer in te geven"
			],
			activeMandate: true,
			dateMandate: null,

			ecurringDateSwitch: false,
      hasPaidEcurringFees: vm.checkEcurringFees('PAID'),
      priceForUnit: null
		}),
		created() {
			this.initialData = Object.assign({}, this.item)
		},
		mounted() {
      //this.loading = this.isLoading;
      // console.log(`load stat ${this.loading}`);
      // this.loading = this.isLoading;
			// console.log('contract data => ', this.item)
			const config = { headers: { 'Content-Type': 'application/json' } };

			if(this.item) {
				this.date = moment(String(this.item.startDate)).format('YYYY-MM-DD'),
				this.endDate = this.item.endDate ? moment(String(this.item.endDate)).format('YYYY-MM-DD'): null,
				this.contractId = this.item._id,
				this.tenant = this.item.tenant['_id'], this.tenantLabel = this.item.tenant['fullName'],
				this.projectName = this.item.project['name'], this.projectNameLabel = this.item.project['name'],
				this.unitName = this.item.unit['_id'], this.unitNameLabel = this.item.unit['name'],
				this.rentPrice = this.item.price,
				this.registrationDocument = this.item.registrationDocument,
				this.newContractStartDate = this.item.overrideEcurringStartDate ? moment(String(this.item.overrideEcurringStartDate)).format('DD/MM/YYYY') : null


				if (this.item.fees && this.item.fees.extraPayments) {
					this.extraPayments = [...this.item.fees.extraPayments]

					for (const i of this.item.fees.extraPayments) {
						i.companyId ? i.ownerCompanyId = i.companyId : i.ownerCompanyId = i.ownerId;
					}
				}

				if (this.item.eCurringSubscriptions !== undefined ) {
					this.overrideEcurringSubscriptions = [...this.item.eCurringSubscriptions];

					for (const i of this.overrideEcurringSubscriptions) {
						i.startDate = moment(String(i.startDate)).format('YYYY-MM-DD')
						i.cancelDate = moment(String(i.cancelDate)).format('YYYY-MM-DD')
						const overrideEcurringData = {bankAccount: '', firstName: '', lastName: ''}
						Object.assign(i, overrideEcurringData)
					}
				}
			}

			if (this.extension && !this.editItem) {
				this.contractId = this.item._id,
				this.defaultValue = this.date,
				this.tenant = this.item.tenant['_id'], this.tenantLabel = this.item.tenant['fullName'],
				this.projectName = this.item.project['name'], this.projectNameLabel = this.item.project['name'],
				this.unitName = this.item.unit['_id'], this.unitNameLabel = this.item.unit['name'],
				this.rentPrice = this.item.price
			}

			api
				.get(`${this.basicUrl}/tenants/languages`, config)
				.then(response => (
					this.languages = response.data.data
				))
			api
				.get(`${this.basicUrl}/units/filter-data`, config)
				.then(response => (
					this.projectNames = response.data.data.projects
				))
				api
				.get(`${this.basicUrl}/contracts/contract-document-statuses`, config)
				.then(response => (
					this.regDocumentStatuses = response.data.data
				))
			api
				.get(`${this.basicUrl}/tenants?minimal=true`, config)
				.then(response => (
					this.tenantNames = response.data.data
				))
		},
		computed: {
			editContract() {
				return this.canEdit.toLowerCase() === 'edit'
			},
			computedDateFormatted () {
        return this.formatDate(this.date, true), this.formatDate(this.endDate, true), this.formatDate(this.dateOfBirth, false)
      },
			isComplete () {
				return this.tenant && this.date && this.endDate && this.projectName && this.unitName && this.rentPrice;
			},
			isTenantComplete () {
				return this.tenantID && this.firstName && this.lastName && this.email;
			}
		},
		watch: {
      name () {
        this.errorMessages = ''
			},
			date (val) {
				if (this.item) {
					this.item.startDate = this.date;
				}
				this.dateFormatted = this.formatDate(this.date, false);
        this.getUnitPrice();
			},
			endDate (val) {
				if (this.item) {
					this.item.endDate = this.endDate;
				}

				this.endDateFormatted = this.formatDate(this.endDate, false);
			},
			ecurringDate (val) {
				this.ecurringDateFormatted = this.formatDate(this.ecurringDate, false);
			},
			dateOfBirth (val) {
				this.dateOfBirthFormatted = this.formatDate(this.dateOfBirth, false)
			},
			rentPrice () {
				if (this.item) {
					this.item.price = this.rentPrice;
				}
			},
    },
		methods: {
			...mapActions({
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
			}),
			getOneTimePayments(val) {
        this.hasChanges = true;
				this.extraPayments = [...val];
			},
      setFeePaySwitches(data){

        if(data){
          this.switchRent = data.rent !=null ? true : null;
          this.switchEGWI = data.egwi !=null ? true : null;
          this.switchDeposit = data.deposit !=null ? false : null;
          this.switchInventory = data.inventory !=null ? false : null; 
          this.switchStudenttax = data.studentTax !=null ? true : null;
        }else{
          this.switchRent =  this.switchEGWI =this.switchDeposit = this.switchInventory = this.switchStudenttax = null;
        }

      },
      checkEcurringFees(feeStatus){
				if (this.item) {
					if (this.item.fees && this.item.prices) {
						const fees = this.item.fees;
						const prices = this.item.prices;

						const {_id, ...feesToLoop} = fees;

						const feesByStatus = Object.keys(feesToLoop).filter(key=>{
							if(prices[key] && prices[key].paymentMethod == 'recurring' && fees[key]== feeStatus){
								return key
							}
						});

						return (feesByStatus && feesByStatus.length > 0)
					}
					return
				}
      },
			computedDateFormattedMomentjs (date) {
        return date ? moment(date).format('DD/MM/YYYY') : ''
      },
			formatDate (date, contractDate) {
        if (!date) return null

        const [year, month, day] = date.split('-')

				if (contractDate === true) {
					return `${day}-${month}-${year}`
				} else {
					return `${day}/${month}/${year}`
				}
			},
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
			},
			filteredProjectNames() {
				if (this.projectName != null) {
					this.unitNameDisabled = false;
          this.rentPrice = null;
					api
					.get(`${this.basicUrl}/units/?minimal=true&projectId=${this.projectName}`)
					.then(response => (
						this.unitNames = response.data.data
					))
				}
			},
      getUnitPrice(){
		let contractStatusType= 'new';
		if(this.item.newStatus && this.item.newStatus == 'EXTEND'){
			contractStatusType = 'extend';
		}else{
			contractStatusType = this.item.type && this.item.type.toLowerCase() == 'verlengd' ? 'extend':'new';
		}
		
        if(this.unitName && this.dateFormatted){
          api
            .get(`${this.basicUrl}/units/${this.unitName}/rent?contractStartDate=${this.dateFormatted}&contractStatus=${contractStatusType}`)
            .then(response => {
              this.rentPrice = response.data.data[0]?.price;
              this.setFeePaySwitches(response.data.data[0]);

              if(!this.rentPrice){
                this.makeToast({ message: 'Er is geen prijs-data aanwezig voor deze periode en unit', type: 'error' });
              }
            })
        }
      },
			createTenant() {
				let data = {
					"tenantId": this.tenantID,
					"email": this.email,
					"fullName": `${this.firstName} ${this.lastName}`,
					"dateofbirth": this.dateOfBirthFormatted,
					"language": this.language
				}

				api.post(`${this.basicUrl}/tenants`, data).then(() => {
					this.showCreateTenant = false,
					this.tenantID = '',
					this.email = '',
					this.firstName = '',
					this.lastName = '',
					this.dateOfBirthFormatted = '',
					this.language = '',
					this.makeToast({
						message: `Huurder werd succesvol toegevoegd`,
						type: 'success',
					});
				}, (error) => {
					this.makeToast({ message: `Er liep iets mis bij het aanmaken van de huurder: ${error}`, type: 'error' });
				})
			},
			saveClicked() {
				this.newSubscriptions = this.overrideEcurringSubscriptions.filter(x => x.switch === true);

        //this.loading = true;
				if (!this.extension) {
					this.$emit('save', {
						unitId: this.unitName,
						tenantId: this.tenant,
						startDate: this.dateFormatted,
						endDate: this.endDateFormatted,
						price: this.rentPrice,
						comment: this.contractComments,
						registrationDocument:this.registrationDocument
					},true);
				} else if (this.editItem) {
					if (this.ecurringDateSwitch) {
						this.$emit('save', {
							extraPayments: this.extraPayments,
							editContract: true,
							resetEcurring: this.resetEcurringSwitch,
							contractID: this.item._id,
							price: this.rentPrice,
							endDate: this.endDate,
							overrideEcurringStartDate: this.ecurringDate,
							registrationDocument:this.registrationDocument
						},true);
					} else {
						this.$emit('save', {
							endDate: this.endDate,
							editContract: true,
              				price: this.rentPrice,
							resetEcurring: this.resetEcurringSwitch,
							contractID: this.item._id,
							overrideEcurringSubscriptions: this.newSubscriptions,
							extraPayments: this.extraPayments,
							registrationDocument:this.registrationDocument
						},true);
					}
				}
				else {
					this.$emit('save', {
						status: "EXTEND",
						contractId: this.contractId,
						unitId: this.unitName,
						tenantId: this.tenant,
						startDate: this.dateFormatted,
						endDate: this.endDateFormatted,
						price: this.rentPrice,
						comment: this.contractComments,
						rent: this.switchRent,
						egwi: this.switchEGWI,
						deposit: this.switchDeposit,
						inventory: this.switchInventory,
						studentTax: this.switchStudenttax,

					},true);
				}
			},
			cancel() {
				this.$emit('close', {
					item: this.item,
				});
				Object.assign(this.item, this.initialData);
			},
		}
	}
</script>

<style lang="scss" scoped>
	$primary-color: #999999;

	.radio-tile-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		button {
			width: 40%;
			margin-top: 20px;
			margin-right: 5%;
		}
	}
</style>
