<template>
	<v-card>
		<v-card-title>
			<h3>Verander status</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex md12 class="pa-1">
					<div v-if="document" class="my-2 d-flex justify-start">
						<v-btn
							depressed
							elevation="2"
							@click="download"
						>
						<v-icon class="mr-2">
							mdi-download
						</v-icon>
						Document
						</v-btn>
					</div>

					<div class="radio-tile-group">
						<v-btn color="green"
							   :outlined="!status"
							   class="white--text"
							   @click="status = true"
						>
							<v-icon dark>mdi-checkbox-marked-circle-outline</v-icon>
						</v-btn>
						<v-btn color="red"
							   :outlined="status"
							   class="white--text"
							   @click="status = false"
						>
							<v-icon dark>mdi-close-circle-outline</v-icon>
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="d-flex justify-end pb-4">
			<v-btn color="primary" @click="saveClicked">Controle Opslaan</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import axios from "axios";

	export default {
		name: "document-dialog",
		props: ['item'],
		data: () => ({
			basicUrl: process.env.VUE_APP_API_URL + '/downloads/documents/',
			status: true,
      		document: null,
			baseContract: null,
			documentType:null
		}),
		mounted() {
			//{item,type:"reg_document"}
			this.baseContract= this.item.item;
			const docs = this.baseContract.documents;
			this.documentType = this.item.type;
      if(docs){
        const foundDocuments = docs.filter(doc=>doc.type == this.documentType);
        this.document = foundDocuments.length > 0 ? foundDocuments[0] : null;
      }
		},
		methods: {
			saveClicked() {
				this.$emit('save',{
					documentId: this.document._id,
					documentStatus: this.status,
					contractId : this.baseContract._id,
					documentType: this.documentType
				});
			},
			async download() {
				console.log(this.document);
				await this.downloadWithAxios(`${this.basicUrl}${this.document.name}`, `${this.document.name}`);
			},
			forceFileDownload(response, title) {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', title)
				document.body.appendChild(link)
				link.click()
			},
			downloadWithAxios(url, title) {
				axios({
					method: 'get',
					url,
					responseType: 'arraybuffer',
				})
				.then((response) => {
					this.forceFileDownload(response, title)
				})
				.catch(() => console.log('error occured'))
			},
		}
	}
</script>

<style lang="scss" scoped>
	$primary-color: #999999;

	.radio-tile-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		button {
			width: 49%;
			margin-top: 0.75rem;
		}
	}
</style>
