<template>
	<div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title">Eigenaars / Bedrijven</h1>
						<!-- <h4 class="text-sm-left grey--text">Alle eigenaren en bedrijven die eigenaar zijn van een unit</h4> -->
						<v-btn x-large color="blue-grey" class="white--text" v-if="canEdit" @click="dialog = true">
							<v-icon small left dark>mdi-plus</v-icon>
							Nieuw bedrijf toevoegen
						</v-btn>
					</div>

					<v-card flat :elevation="0">
						<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$companyList'"
								:showFilters="false"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>
						</v-card-title>


						<v-data-table
							:headers="headers"
							:items="companiesV2"
							:options.sync="options"
							:server-items-length="totalCompanies"
							:footer-props="{'items-per-page-options': [5, 10, 15]}"
							:loading="loading"
							item-key="email"
						>
							<template v-slot:body="{ items }">
								<tbody>
								<tr v-for="item in items">
									<td class="text-xs-left">{{ item.name }}</td>
									<td class="text-xs-left">{{ item.bankAccount }}</td>
									<td class="text-xs-left">{{ item.accountName }}</td>
									<td class="align-end px-0">
										<v-spacer></v-spacer>
										<v-icon v-if="canEdit" @click="openCompanyEdit(item)">
											mdi-account-edit
										</v-icon>
										<v-icon v-if="canEdit" @click="confirmDeleteDialogItem = item; confirmDeleteDialog = true">
											mdi-delete
										</v-icon>
									</td>
								</tr>
								</tbody>
							</template>
							<template slot="no-data">
								<v-alert :value="true" color="grey" icon="mdi-warn">
									Sorry, geen eigenaren gevonden
								</v-alert>
							</template>
						</v-data-table>
					</v-card>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog v-model="dialog" max-width="700px">
			<company-edit :editedItem="editedItem" @save="save" @close="close"></company-edit>
		</v-dialog>
		<v-dialog v-model="confirmDeleteDialog" v-if="canEdit" max-width="400px">
			<confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
			                @cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
			                @delete="deleteCompany"></confirm-delete>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
	import { ACTIONS } from '../companies.constants';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
	import CompanyEdit from '../components/company-edit';
import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';
import ConfirmDelete from '@components/dialog/confirm-delete';
import api from '../../../../services/backendApi';
import { debounce } from 'debounce'
import FilterDataDialogComponent from '../../../../components/data-filter/filter-data-dialog.component.vue';

	export default {
		components: { ConfirmDelete, CompanyEdit, FilterDataDialogComponent },
		data: () => ({
			acceptedFilters: [],
			filterParams: {},
			getAllCompanies: '',
			companiesV2: [],
			totalCompanies: 0,
			loading: true,
			options: {},
			basicUrl: process.env.VUE_APP_API_URL,
			companiesFilter: [],
			feesFilter: [],
			endpointFilter: null,
			endpointSort: null,
			endpointSearch: null,
			endpointData: null,

			dialog: false,
			editedIndex: -1,
			confirmDeleteDialog: false,
			confirmDeleteDialogItem: null,
			editedItem: {
					name: '',
						eCurringKey: '',
						key: '',
						accountName :'',
            bankAccount:'',
            type:''
			},
			loading: false,
			headers: [
				{ text: 'Naam', value: 'name', sortable: false  },
				{ text: 'Rekeningnummer', value: 'bankAccount' , sortable: false },
				{ text: 'Rekeninghouder', value: 'roomType', sortable: false  },
				{ text: '', align: 'right', sortable: false },
			],
		}),
		watch: {
			dialog(val) {
				val || this.close()
			},
			options: {
				handler () {
					this.getDataFromApi()
				},
				deep: true,
			},
		},
		mounted() {
		},
		computed: {
			...mapState({
				companies: state => state.companies.companies,
			}),
			...mapGetters({
				role: GETTERS.ROLE,
				getRights: GETTERS.RIGHTS
			}),
			canEdit() {
				return this.getRights.SETTINGS_MOLLIE.toLowerCase() === 'edit';
			}
		},
		methods: {
			...mapActions({
				getCompanies: ACTIONS.GET_ALL_COMPANIES,
				updateCompany: ACTIONS.UPDATE_COMPANY,
				createCompany: ACTIONS.CREATE_COMPANY,
				removeCompany: ACTIONS.DELETE_COMPANY,
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
			}),
			setFilterOnList(filterDataParams){
				this.filterParams = filterDataParams;
				this.getDataFromApi();
			},
			openCompanyEdit(item) {
				this.editedItem = Object.assign(this.editedItem, item);
				this.dialog = true
			},
			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = {
						name: '',
						eCurringKey: '',
						key: '',
						accountName :'',
            bankAccount:'',
            type:''
					};
					this.editedIndex = -1
				})
			},
			deleteCompany(item) {
				api.delete(`${this.basicUrl}/owners/companies-owners/${item._id}`).then(() => {
					this.makeToast({
						message: `Eigenaar/bedrijf succesvol verwijderd`,
						type: 'success',
					});
					this.getDataFromApi();
				}, (error) => {
					this.makeToast({ message:'Er liep iets mis bij het verwijderen van de eigenaar/bedrijf', type: 'error' });
				})

				this.confirmDeleteDialog = false;
				this.confirmDeleteDialogItem = null;
			},
			save(company) {
				this.close();
				if (company._id) {
					api.patch(`${this.basicUrl}/owners/companies-owners/${company._id}`, company).then(() => {
						this.makeToast({
							message: `Company succesvol aangepast`,
							type: 'success',
						});
						this.getDataFromApi();
					}, (error) => {
						this.makeToast({ message:'Er liep iets mis bij het aanpassen van de eigenaar', type: 'error' });
					})
				} else {

          // TODO needs to be changed when it is able to create owner/company on backend
					let data = {
						"name": company.name,
						"key": company.key,
						"eCurringKey": company.eCurringKey,
						"account":{
								"name": company.accountName,
								"number": company.bankAccount,
						}
					};

					api.post(`${this.basicUrl}/companies`, data).then(() => {
						this.makeToast({
							message: `Company werd succesvol aangemaakt`,
							type: 'success',
						});
						this.getDataFromApi();
					}, (error) => {
						this.makeToast({ message:'Er liep iets mis bij het aanmaken van de eigenaar', type: 'error' });
					})
				}

			},

			getDataFromApi () {
				this.loading = true
				this.companiesCall().then(data => {
					this.companiesV2 = data.items
					this.totalCompanies = data.total
					this.loading = false
				})
			},

			companiesCall () {
				const config = { headers: { 'Content-Type': 'application/json' } };

				return new Promise((resolve, reject) => {
					const { sortBy, sortDesc, page, itemsPerPage } = this.options

					if (sortBy.length === 1 && sortDesc.length === 1) {
						sortDesc[0] ?
						this.filterParams.sortBy = `${sortBy[0]}:1` :
						this.filterParams.sortBy = `${sortBy[0]}:-1`
					} else {
						this.filterParams.sortBy = null
					}

					const params = {
						skip: page-1,
						limit: itemsPerPage,
						... this.filterParams
					};

					api.get(`${this.basicUrl}/owners/companies-owners`, {config, params})
					.then(function (response) {
						let items = response.data.data;
						const total = response.data.totalCount;
            resolve({	items,total});
					})
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.filter {
		&--container {
			width: 100%;
		}

		&-select {
			max-width: 300px;
		}
	}

	.container {
		margin-left: 250px;
		margin-right: 250px;
		max-width: calc(100vw - 300px);
	}

	.form-title {
		display: flex;
	}

	tr {
		background-color: #ffffff;
	}

	tr:nth-child(odd) {
		background-color: #f6f6f6;
	}
</style>
