<template>
	<v-container grid-list-md text-xs-center class="container">
		<v-flex md12 xs12 class="ma-5">
			<v-card class="card pa-4">
				<div class="header">
					<h1 class="secondary-text text-lg-left form-title d-flex align-center">
            <v-icon x-large class="mr-2" color="#000">mdi-cancel</v-icon>
						Geen toegang
					</h1>
				</div>
			</v-card>
		</v-flex>
	</v-container>
</template>

<script>

export default {

};

</script>
