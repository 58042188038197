<template>
	<v-card>
		<v-card-title>
			<h3 class="my-4">Mail-log van: {{item.email}}</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex class="pa-3">
					<v-card v-for="(log, key) in item.logs.slice().reverse()" elevation="2" class="comments pa-4 mb-2" :key="key">
						<div class="d-flex justify-space-between">
							<p class="text-caption mb-1 font-weight-light">{{ log.time | formatDateLogs }}</p>
							<p class="text-caption mb-1 font-weight-light">
								<v-icon color="green" v-if="log.status === 'DONE'">
									mdi-email-check
								</v-icon>
								<v-icon v-else color="red">
									mdi-email-off
								</v-icon>
							</p>
						</div>
						
						{{ log.template }}
					</v-card>
					<p v-if="item.logs.length === 0" >Er werden nog geen mails gestuurd naar {{item.email}}.</p>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="mr-2">
		</v-card-actions>
	</v-card>
</template>

<script>
	// import { mapActions } from 'vuex'
	// import { ACTIONS } from '../../units/units.constants';

	export default {
		name: "maillog-dialog",
		props: ['item'],
		data: () => ({
			status: true,
		}),
		mounted() {
		},
		computed: {
		},
		watch: {
    },
		methods: {
		}
	}
</script>

<style lang="scss" scoped>
	$primary-color: #999999;

	.radio-tile-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		button {
			width: 40%;
			margin-top: 20px;
			margin-right: 5%;
		}
	}
</style>
