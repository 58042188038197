<template>
  <div>
    <v-layout row wrap>
      <v-flex md12 xs12 >
        <v-card class="card pa-4">
          <div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title ml-4">Mail Templates</h1>
            <v-btn x-large color="blue-grey" class="white--text" @click="selectedMailTemplate = null; showMailTemplateDialog = true; isEdit = false;" v-if="canEdit">
              <v-icon small left dark>mdi-note-plus-outline</v-icon>
              Aanmaken
            </v-btn>
					</div>

          <v-card flat :elevation="0">
            <v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$mailTemplateList'"
								:showFilters="false"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>
						</v-card-title>

            <v-data-table
              :items="mailTemplates"
              :options.sync="options"
              :headers="headers"
              :server-items-length="totalCount"
              :loading="isLoading"
              :footer-props="{'items-per-page-options': [5, 10, 15]}"
              @update:page="setSkip"
              @update:items-per-page="setLimit"
              @update:sort-by="setSort"
              @update:sort-desc="setSort">

	            <!-- The following template will provide an override for the edit cells -->
              <template v-slot:item.edit="{ item }">
								<td>
									<v-btn icon @click="selectedMailTemplate = item; showMailTemplateDialog=true;  isEdit = true;">
										<v-icon medium dark>mdi-note-edit-outline</v-icon>
									</v-btn>
								</td>
              </template>

            	<!-- The following template will provide an override for the delete cells -->
              <template v-slot:item.delete="{ item }">
								<td v-if="!item.backendKey">
									<v-btn icon @click="selectedMailTemplate = item;showDelete=true" v-if="canEdit">
										<v-icon medium dark>mdi-delete-outline</v-icon>
									</v-btn>
								</td>
              </template>

              <!-- The following template will provide a custom fallback when no units are provided or found -->
              <template slot="no-data">
                <v-alert :value="true" color="grey" icon="mdi-warn">
                  Sorry, geen mail templates gevonden
                </v-alert>
              </template>

            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>


    		<!-- Mail Template edit/new -->
		<v-dialog v-model="showMailTemplateDialog" v-if="showMailTemplateDialog" max-width="700px" scrollable persistent>
      <mail-template-dialog :mailTemplate="selectedMailTemplate" :isEdit="isEdit" @save="upsertTemplate" @close="showMailTemplateDialog = false" :canEdit="canEdit"></mail-template-dialog>
		</v-dialog>

    <!-- delete -->
		<v-dialog v-model="showDelete" max-width="400px">
			<confirm-delete v-if="showDelete" :item="selectedMailTemplate"
										@cancel="selectedMailTemplate = null; showDelete = false"
										@delete="deleteTemplate"></confirm-delete>
		</v-dialog>

  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from "vuex";
  import { isNil, isEmpty, pathOr } from "ramda";
  import debounce from "lodash.debounce";
  import { INITIAL_FILTERS, MAIL_TEMPLATE_LIST_HEADERS,FILTER_SORT_OPTIONS } from "../../mail-templates/list/mail-template-list.const";
  import ConfirmDelete from '@components/dialog/confirm-delete';
  import mailTemplateDialog from '../components/mail-template.dialogue.vue';
  import { MAIL_TEMPLATES_ACTIONS } from "../store/mail-template.store";
  import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
  import { mapMailTemplates } from "../helpers";
  import FilterDataDialogComponent from '../../../../components/data-filter/filter-data-dialog.component.vue';
  import { GETTERS } from '@/account/account.constants';
  import { Roles } from '@/account/account.store';

export default ({
  components: { FilterDataDialogComponent, ConfirmDelete, mailTemplateDialog },
  data:()=> ({
    acceptedFilters: [],
		filterParams: {},
    currentFilters: INITIAL_FILTERS,
    headers: MAIL_TEMPLATE_LIST_HEADERS,
    options: {},
    isEdit : false,
    selectedMailTemplate: null,
		showMailTemplateDialog: false,
    showDelete:false,
  }),
  mounted:function(){
    this.getMailTemplatesData();
  },
  computed: {
    ...mapState({
      mailTemplates: (state) => pathOr([], ["mailTemplates","mailTemplates"], state),
      isLoading: (state) => pathOr(false, ["mailTemplates", "isLoading"], state),
      totalCount: (state) => pathOr(0, ["mailTemplates", "totalCount"], state),
    }),
    ...mapGetters({
        getRole: GETTERS.ROLE,
        getRights: GETTERS.RIGHTS
      }),
      canEdit() {
          return this.getRights.PROJECTS_INFO.toLowerCase() === 'edit'
      }
  },
  watch: {

  },
  methods:{
    ...mapActions({
      getMailTemplates : MAIL_TEMPLATES_ACTIONS.GET_MAIL_TEMPLATES,
      createMailTemplate :  MAIL_TEMPLATES_ACTIONS.CREATE_MAIL_TEMPLATE,
      updateMailTemplate :  MAIL_TEMPLATES_ACTIONS.UPDATE_MAIL_TEMPLATE,
      deleteMailTemplate :  MAIL_TEMPLATES_ACTIONS.DELETE_MAIL_TEMPLATE,
      makeToast: TOAST_ACTIONS.MAKE_TOAST,
    }),
    setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;
			this.currentFilters = {...this.filterParams}
      this.getMailTemplatesData(this.filterParams);
    },
    getMailTemplatesData:function(){
      this.getMailTemplates(this.currentFilters)
    },
    upsertTemplate(data,{isEdit}){
      if(isEdit){
        this.updateTemplate(data);
      }else{
        this.createTemplate(data);
      }
    },
    successfulCRUD(msg){
      this.showMailTemplateDialog = false;
      this.showDelete = false;
      this.selectedMailTemplate = null;
      this.makeToast({
            message: msg,
            type: "success",
          })
    },
    createTemplate(data){
        delete data._id;

      this.createMailTemplate({
        params: this.currentFilters,
        val: data
      }).then(()=>{
      this.successfulCRUD(`Mail template koppeling is aangemaakt`);
      },(error)=>{
        this.makeToast({ message:'Er liep iets mis bij het aanmaken van een mail template koppeling', type: 'error' });
      })
    },
      updateTemplate(data){
      this.updateMailTemplate({
        params: this.currentFilters,
        val: data
      }).then(()=>{
        this.successfulCRUD(`Mail template koppeling is aangepast`);
      },(error)=>{
        this.makeToast({ message:'Er liep iets mis bij het aanpassen van een mail template koppeling', type: 'error' });
      })
    },
    deleteTemplate(templ){
      this.deleteMailTemplate({params:this.currentFilters,val:templ})
      .then(() => {
				this.successfulCRUD(`Mail Template werd verwijderd.`);
			}, (error) => {
				this.makeToast({ message:'Er liep iets mis bij het verwijderen van de mail template', type: 'error' });
			})
    },
    setSkip: function () {
      this.currentFilters.skip = this.options.page -1;
      this.getMailTemplatesData();
    },
    resetSkip: function () {
      this.options.page = 1;
      this.currentFilters.skip = 0;
    },
    setLimit: function () {
      this.currentFilters.limit = this.options.itemsPerPage;
      this.getMailTemplatesData();
    },
    setSort: function () {
      const selectedSort = this.options.sortBy[0];
      const sortIsDesc = this.options.sortDesc[0];

      if (isNil(selectedSort)) {
        this.currentFilters.sortBy = null;

        this.getMailTemplatesData();
        return;
      }

      this.currentFilters.sortBy = sortIsDesc
        ? (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:1`)
        : (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:-1`);

      this.getMailTemplatesData();
    },
  },
})
</script>

<style lang="scss" scoped>
	.filter {
    &--container {
      width: 100%
    }
  }
</style>
