import api from '../../../services/backendApi';
import { MUTATIONS } from '@views/admin/permissions/permissions.constants';

// NOG AAN TE PASSEN
const permissionsUrl = '/roles';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getRoles() {
    return api.get(`${permissionsUrl}`, config);
  }
};
