<template>
	<v-card>
		<v-card-title>
			<h3>Controleer dit passport</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex md5 class="ma-5">
					<h2 class="text-center mb-3"> VOORKANT </h2>
					<v-img :src="basicUrl + item.tenant.passport.frontImg"></v-img>
					<div class="radio-tile-group">
						<v-btn color="green"
						       :outlined="!front"
						       class="white--text"
						       @click="front = true"
						>
							<v-icon dark>mdi-checkbox-marked-circle-outline</v-icon>
						</v-btn>


						<v-btn color="red"
						       :outlined="front"
						       class="white--text"
						       @click="front = false"
						>
							<v-icon dark>mdi-close-circle-outline</v-icon>
						</v-btn>
					</div>
				</v-flex>
				<v-flex md5 class="ma-5">
					<h2 class="text-center mb-3"> ACHTERKANT </h2>
					<v-img :src="basicUrl + item.tenant.passport.backImg"></v-img>
					<div class="radio-tile-group">
						<v-btn color="green"
						       :outlined="!back"
						       class="white--text"
						       @click="back = true"
						>
							<v-icon dark>mdi-checkbox-marked-circle-outline</v-icon>
						</v-btn>


						<v-btn color="red"
						       :outlined="back"
						       class="white--text"
						       @click="back = false"
						>
							<v-icon dark>mdi-close-circle-outline</v-icon>
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="mr-2">
			<v-btn color="secondary" outlined @click="downloadImages">Download Afbeeldingen</v-btn>

			<v-spacer></v-spacer>
			<v-btn color="primary" @click="saveClicked">Controle Opslaan</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import axios from "axios";

export default {
	name: "passport-dialog",
	props: ['item'],
	data: () => ({
		basicUrl: process.env.VUE_APP_API_URL + '/images/',
		front: true,
		back: true,
	}),
	methods: {
		saveClicked() {
			let validationCheck;
			this.front && this.back ? validationCheck = true : validationCheck = false;

			this.$emit('save', {
				_id: this.item._id,
				passportValid: validationCheck,
				tenantID: this.item.tenant['_id'],
        contractId: this.item._id,
        brandName: this.item.brand.name
			});
		},
		async downloadImages() {
			await this.downloadWithAxios(`${this.basicUrl}${this.item.tenant.passport.frontImg}`, `${this.item.tenant.fullName}-voorkant.jpg`);
			await this.downloadWithAxios(`${this.basicUrl}${this.item.tenant.passport.backImg}`, `${this.item.tenant.fullName}-achterkant.jpg`);
		},
		forceFileDownload(response, title) {
			console.log(title)
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', title)
			document.body.appendChild(link)
			link.click()
		},
		downloadWithAxios(url, title) {
			axios({
				method: 'get',
				url,
				responseType: 'arraybuffer',
			})
					.then((response) => {
						this.forceFileDownload(response, title)
					})
					.catch(() => console.log('error occured'))
		},
	}
}
</script>

<style lang="scss" scoped>
$primary-color: #999999;

.radio-tile-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	button {
		width: 40%;
		margin-top: 20px;
		margin-right: 5%;
	}
}
</style>
