export const MODULE_NAME = 'companies';

export const ACTIONS = {
	GET_ALL_COMPANIES: `${MODULE_NAME}/GET_ALL_COMPANIES`,
	GET_DETAIL_COMPANY: `${MODULE_NAME}/GET_DETAIL_COMPANY`,
	UPDATE_COMPANY: `${MODULE_NAME}/UPDATE_COMPANY`,
	CREATE_COMPANY: `${MODULE_NAME}/CREATE_COMPANY`,
	DELETE_COMPANY: `${MODULE_NAME}/DELETE_COMPANY`,
	UPLOAD_FILE: `${MODULE_NAME}/UPLOAD_FILE`,
	GET_SUBSCRIPTION_PLANS_FOR_COMPANY: `${MODULE_NAME}/GET_SUBSCRIPTION_PLANS_FOR_COMPANY`,
};


export const MUTATIONS = {
	SET_ALL_COMPANIES: `${MODULE_NAME}/SET_ALL_COMPANIES`,
	SET_DETAIL_COMPANY: `${MODULE_NAME}/SET_DETAIL_COMPANY`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
	SET_SUBSCRIPTION_PLANS: `${MODULE_NAME}/SET_SUBSCRIPTION_PLANS`,
};
