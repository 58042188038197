export const MODULE_NAME = 'tenantEntries';

export const ACTIONS = {
  GET_ALL_TENANTS: `${MODULE_NAME}/GET_ALL_TENANTS`,
  GET_DETAIL_TENANT: `${MODULE_NAME}/GET_DETAIL_TENANT`,
  UPDATE_TENANT: `${MODULE_NAME}/UPDATE_TENANT`,
  UPLOAD_FILE: `${MODULE_NAME}/UPLOAD_FILE`,
  SET_PASSPORT_CHECK: `${MODULE_NAME}/SET_PASSPORT_CHECK`,
  SET_PAYMENT_CHECK: `${MODULE_NAME}/SET_PAYMENT_CHECK`,
  MAIL_ALL: `${MODULE_NAME}/MAIL_ALL`,
  MAIL_PERSON: `${MODULE_NAME}/MAIL_PERSON`,
  MAIL_TENANTS_SELECTION: `${MODULE_NAME}/MAIL_TENANTS`,
  MAIL_ENTRIES_SELECTION: `${MODULE_NAME}/MAIL_ENTRIES`,
  DELETE_TENANT: `${MODULE_NAME}/DELETE_TENANT`,
  UPLOAD_TENANT: `${MODULE_NAME}/UPLOAD_TENANT`,
  UPDATE_REMARKS: `${MODULE_NAME}/UPDATE_REMARKS`,
  REMOVE_REMARK: `${MODULE_NAME}/REMOVE_REMARK`,
  DOWNLOAD_RENTAL_DIARY: `${MODULE_NAME}/DOWNLOAD_RENTAL_DIARY`,
  DOWNLOAD_ENTRIES: `${MODULE_NAME}/DOWNLOAD_ENTRIES`,
};


export const MUTATIONS = {
  SET_ALL_TENANTS: `${MODULE_NAME}/SET_ALL_TENANTS`,
  SET_DETAIL_TENANT: `${MODULE_NAME}/SET_DETAIL_TENANT`,
  SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
