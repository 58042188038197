import { ACTIONS, MUTATIONS } from './companies.constants';
import companiesService from './companies.service';

const payment = {
	state: {
		loading: false,
		companies: [],
		detailCompany: {},
		subscriptionPlans: [],
	},
	actions: {
		[ACTIONS.GET_ALL_COMPANIES]({ commit }, filter) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				companiesService.getCompanies(filter)
					.then((result) => {
						commit(MUTATIONS.SET_ALL_COMPANIES, result.data.companies);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.GET_DETAIL_COMPANY]({ commit }, id) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				companiesService.getCompanyDetail(id)
					.then((result) => {
						commit(MUTATIONS.SET_DETAIL_COMPANY, result.data);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.GET_SUBSCRIPTION_PLANS_FOR_COMPANY]({ commit }) {
			return new Promise((resolve, reject) => {
				companiesService.getSubscriptionPlans()
					.then((result) => {
						commit(MUTATIONS.SET_SUBSCRIPTION_PLANS, result.data),
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
			});
		},
		[ACTIONS.UPDATE_COMPANY]({ commit, dispatch }, company) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				companiesService.updateCompany(company)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_COMPANIES);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.CREATE_COMPANY]({ commit }, company) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				companiesService.createCompany(company)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_COMPANIES);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
		[ACTIONS.DELETE_COMPANY]({ commit, dispatch }, company) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
				companiesService.deleteCompany(company)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_COMPANIES);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_ALL_COMPANIES](state, companies) {
			state.companies = companies;
		},
		[MUTATIONS.SET_DETAIL_COMPANY](state, company) {
			state.detailCompany = company;
		},
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
		[MUTATIONS.SET_SUBSCRIPTION_PLANS](state, subscriptionPlans) {
			state.subscriptionPlans = subscriptionPlans;
		},
	},
};


export default payment;
