<template>
	<div>
		<v-layout row wrap>
			<v-card flat :elevation="0" class="pt-4 pa-4">
				<v-layout class="mr-3 mb-5 flex justify-end flex-column">

					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title">Intredes</h1>
						<div class="flex pr-0">
							<div class="mt-3 d-flex justify-end">
								<v-btn
									x-large
									color="blue-grey"
									class="white--text"
                  :loading="dlEntries"
                  :disabled="loading"
									@click="getEntriesList"
								>
									<v-icon small left dark>mdi-file-excel</v-icon>
									Download Intredes
								</v-btn>
							</div>
						</div>
					</div>


					<div>
						<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$entriesList'"
								:showFilters="true"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>
						</v-card-title>
					</div>


					<div class="flex-row d-inline-flex align-center" v-if="canEdit">
						<v-autocomplete class="template-select mr-5" dense outlined hide-details small
              label="Mail template kiezen"
              clearable
              :items="mailTemplates"
              item-text="name"
              item-value="_id"
              v-model="selectedMailTemplate"
              @change="toggleDisableButton"
            ></v-autocomplete>
						<v-btn outlined @click="mailSelected()" :disabled="disableMail">
							<v-icon class="mr-2" color="primary">mdi-email-outline</v-icon>
							Onderstaande selectie mailen
						</v-btn>
					</div>
				</v-layout>

				<v-data-table
						:headers="getHeaders"
						:items="tenantsV2"
						:search="search"
						:options.sync="options"
						:server-items-length="totalTenants"
						:loading="loading"
						item-key="email"
						:footer-props="{'items-per-page-options': [5, 10, 15]}"
				>
					<template v-slot:body="{ items }">
						<tbody>
						<tr v-for="item in items" >
              <td v-if="extended" :class="`status-border-${item.paymentDue.statusCode}`">
                <v-menu>
                  <template v-slot:activator="{on,attrs}">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                   <v-list dense>
                    <v-list-item
                      v-for="(menuItem, i) in menuItems"
                      :key="i"
                      @click="menuItemClicked(menuItem.id,item)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="menuItem.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="menuItem.label"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list dense>
                  <v-list-item @click="menuItemClicked('delete', item)">
                    <v-list-item-icon>
                        <v-icon >mdi-delete-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Verwijderen</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list>
                  </v-card>
                </v-menu>
              </td>
							<td class="text-xs-left" v-if="!extended">{{ item.tenant.tenantId }}</td>

							<td class="text-xs-left">{{ item.startDate | formatDate }}</td>
							<td class="text-xs-left">{{ item.endDate | formatDate }}</td>
							<td class="text-xs-left">{{ item.project.name }}
								</td>
							<td class="text-center">
								<router-link :to="{ name: 'unitDetail', params: { id: item.unit._id }}" target='_blank'>
									{{ item.unit.name }}
								</router-link>
							</td>
							<!-- <td class="text-xs-left">{{ item.tenant.language }}</td> -->
							<td class="text-xs-left">{{ item.tenant.fullName }}</td>
							<!-- <td class="text-xs-left">{{ item.tenant.email }}</td> -->

						 	<td class="justify-center" v-if="extended" v-for="payment in payments">
								<span v-if="item.prices && item.prices[payment.id]">
									<v-icon v-if="item.fees[payment.id] === 'PAID'"
													@click="paymentDialogItem = {_id: item.fees['_id'], payment, documents: item.documents,tenantId: item.tenant._id,brandName:item.brand.name}; paymentDialog = true; checkToUpdate = item.fees"
													class="mr-2" color="green">
										mdi-checkbox-marked-circle-outline
									</v-icon>
									<v-icon v-else-if="item.fees[payment.id] === 'TO_CHECK'"
													@click="paymentDialogItem = {_id: item.fees['_id'], payment, documents: item.documents,tenantId: item.tenant._id,brandName:item.brand.name}; paymentDialog = true; checkToUpdate = item.fees"
													class="mr-2" color="orange">
										mdi-alert-circle-outline
									</v-icon>
									<v-icon v-else-if="item.fees[payment.id] === 'UNPAID'"
													class="mr-2" color="red"
													@click="paymentDialogItem = {_id: item.fees['_id'], payment, documents: item.documents,tenantId: item.tenant._id,brandName:item.brand.name}; paymentDialog = true; checkToUpdate = item.fees">
										mdi-close-circle-outline
									</v-icon>
									<v-icon v-else></v-icon>
								</span>
							</td>

              <td>
                <span v-if="item.fees.extras">
                  <v-icon v-if="item.fees.extras === 'PAID'" class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon>
                  <v-icon v-if="item.fees.extras === 'UNPAID'" class="mr-2" color="red">mdi-close-circle-outline</v-icon>
                  <v-icon v-if="item.fees.extras === 'TO_CHECK'" class="mr-2" color="orange">mdi-alert-circle-outline</v-icon>
					<v-icon v-else></v-icon>
                </span>
              </td>

			  <td>
                <span v-if="item.registrationDocument" @click="documentDialogItem = {item:item,type:'reg_document'}; documentDialog = true; checkToUpdate = item">
					<v-icon v-if="item.registrationDocument === 'APPROVED'" class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon>
					<v-icon v-if="item.registrationDocument === 'NOT_PRESENT'" class="mr-2" color="red">mdi-close-circle-outline</v-icon>
					<v-icon v-if="item.registrationDocument === 'TO_JUDGE'" class="mr-2" color="orange">mdi-alert-circle-outline</v-icon>
                </span>
              </td>
<!-- //{_id: item._id, payment:null, documents: item.documents,tenantId: item.tenant._id,brandName:item.brand.name} -->
							<td class="justify-center" v-if="extended">
								<v-icon v-if="item.tenant['passportStatus'] === 'NOT_PRESENT'"
								        @click="dialogItem = item; dialog = true, checkToUpdate = item.tenant"
								        class="mr-2" color="red">
									mdi-close-circle-outline
								</v-icon>
								<v-icon v-else-if="item.tenant['passportStatus'] === 'TO_JUDGE'"
								        @click="dialogItem = item; dialog = true, checkToUpdate = item.tenant"
								        class="mr-2" color="orange">
									mdi-alert-circle-outline
								</v-icon>
								<v-icon v-else @click="dialogItem = item; dialog = true, checkToUpdate = item.tenant"
								        class="mr-2" color="green">
									mdi-checkbox-marked-circle-outline
								</v-icon>
							</td>

							<td class="justify-center" v-if="extended">
								<v-icon v-if="item.signed === true" class="mr-2" color="green">
									mdi-checkbox-marked-circle-outline
								</v-icon>
								<v-icon v-else class="mr-2" color="red">
									mdi-close-circle-outline
								</v-icon>
							</td>

							<td class="justify-center text-center">
								{{item.signedAtDate | formatDate}}
							</td>

							<td>
								{{ item.type }}
							</td>
						</tr>
						</tbody>
					</template>
					<template slot="no-data">
						<v-alert :value="true" color="grey" icon="mdi-warn">
							Sorry, geen gebruikers gevonden
						</v-alert>
					</template>
				</v-data-table>

				<!-- <v-file-input
						v-if="canEdit"
						outlined
						class="file-input"
						prepend-inner-icon="mdi-paperclip"
						prepend-icon=""
						accept="text/comma-separated-values"
						placeholder="bestand uploaden"
						v-model="file"
				></v-file-input> -->
        <div  class="d-flex justify-space-between mb-6">
          <!-- <v-btn v-if="canEdit" outlined :loading="loading" :disabled="!file" @click="uploadFile">bestand opladen (vanuit Omniboxx)</v-btn> -->
          <v-btn :loading="dlRentalDiaryLoading" :disabled="loading || !canDownload"	@click="getRentalDiaryData" outlined class="ml-3">Download huurdagboek data</v-btn>
        </div>

			</v-card>
		</v-layout>
		<v-dialog v-model="dialog" v-if="canEdit" max-width="700px">
			<passport-dialog v-if="dialog" :item="dialogItem" @save="updateTenant"></passport-dialog>
		</v-dialog>
		<v-dialog v-model="paymentDialog" v-if="canEdit" max-width="300px">
			<payment-dialog v-if="paymentDialog" :item="paymentDialogItem" @save="updateTenantPayment"></payment-dialog>
		</v-dialog>
		<v-dialog v-model="documentDialog" v-if="canEdit" max-width="300px">
			<document-dialog v-if="documentDialog" :item="documentDialogItem" @save="updateContractDocument"></document-dialog>
		</v-dialog>
		<v-dialog v-model="confirmDeleteDialog" v-if="canEdit" max-width="400px">
			<confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
			                @cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
			                @delete="deleteContract"></confirm-delete>
		</v-dialog>
		<v-dialog v-model="MaillogDialog" v-if="canEdit" max-width="700px">
			<maillog-dialog v-if="MaillogDialog" :item="MaillogDialogItem"></maillog-dialog>
		</v-dialog>
		<v-dialog v-model="createContractDialog" v-if="createContractDialog" max-width="880px" scrollable persistent>
			<create-contract-dialog :isLoading="isSavingContract" :extension="extension" :item="createContractDialogItem" :editItem="editContract" @save="createContract" @close="closeContractDetail" :canEdit="getRights.FOLLOW_UP_ENTIES" ></create-contract-dialog>
		</v-dialog>
		<v-dialog v-model="commentDialog" v-if="canEdit" max-width="700px">
			<comments-dialog v-if="commentDialog" :item="commentDialogItem" @save="updateRemarks" @delete="deleteRemark"></comments-dialog>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { isNil, isEmpty } from 'ramda';

import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';
import ConfirmDelete from '@components/dialog/confirm-delete';
import createContractDialog from '@views/admin/entries/components/create-contract-dialog';
import PaymentDialog from '@views/admin/entries/components/payment-dialog';
import DocumentDialog from '@views/admin/entries/components/document-dialog';
import MaillogDialog from '@views/admin/entries/components/maillog-dialog';
import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
import PassportDialog from '../components/passport-dialog';
import api from '../../../../services/backendApi';
import { ACTIONS } from '../entries.constants';
import CommentsDialog from '@views/admin/entries/components/comments-dialog';
import { debounce } from "debounce";
import { DATA_FILTERS } from '@/components/data-filter/const.js';
import FilterDataDialogComponent from '@components/data-filter/filter-data-dialog.component.vue';

const ENTRIES_ACCEPTED_FILTERS = [
	DATA_FILTERS.COLOR_PAYMENT_STATUSES,
	DATA_FILTERS.START_DATE,
	DATA_FILTERS.END_DATE,
	DATA_FILTERS.PROJECTS,
	DATA_FILTERS.UNITS,
	DATA_FILTERS.DEPOSIT,
	DATA_FILTERS.INVENTORY,
	DATA_FILTERS.RENT,
	DATA_FILTERS.EGWI,
	DATA_FILTERS.STUDENT_TAX,
	DATA_FILTERS.EXTRAS,
	DATA_FILTERS.PASSPORT_STATUSES,
	DATA_FILTERS.CONTRACT_SIGNED_STATUSES,
	DATA_FILTERS.CONTRACT_SIGNED_AT,
	DATA_FILTERS.CONTRACT_TYPES,
	DATA_FILTERS.REGISTRATION_DOCUMENT
];

export default {
	components: {
    FilterDataDialogComponent,
    CommentsDialog,
    ConfirmDelete,
    PaymentDialog,
	DocumentDialog,
    PassportDialog,
    createContractDialog,
    'maillog-dialog': MaillogDialog },
	props: {
		extended: {
			type: Boolean,
		},
	},
	data: () => ({
    acceptedFilters: ENTRIES_ACCEPTED_FILTERS,
    filterParams:{},
		bundle_item: null,
    contractStatuses: [{'name': 'Getekend', _id: true}, {'name': 'Niet getekend', _id: false}],
		contractData: {},
		extension: false,
		getAllTenants: '',
		tenantsV2: [],
		totalTenants: 0,
		loading: true,
    dlRentalDiaryLoading:false,
    dlEntries:false,
		options: {},
		basicUrl: process.env.VUE_APP_API_URL,
		tenantFilter: [],
		feesFilter: [],
		endpointFilter: null,
		endpointSort: null,
		endpointSearch: null,
		commentDialog: false,
		commentDialogItem: null,
		checkToUpdate: null,
		disableMail: true,
		search: '',
		dialog: false,
		dialogItem: null,
		paymentDialog: false,
		documentDialog:false,
		paymentDialogItem: null,
		documentDialogItem:null,
		confirmDeleteDialog: false,
		confirmDeleteDialogItem: null,
		createContractDialog: false,
		createContractDialogItem: null,
		MaillogDialog: false,
		MaillogDialogItem: null,
		file: null,
		selectedUser: null,
		mailTemplates: [],
		selectedMailTemplate: null,
		editContract: false,
    isSavingContract:false,
		pagination: {
			sortBy: 'name'
		},
		menu: {
			'startDate': false,
			'endDate': false,
      'signedAtDate':false,
		},
		formatedDates: {
			'startDate': null,
			'endDate': null,
      'signedAtDate':null,
		},
		menuItems: [
			{ id: 'contract', label: 'Detail',icon:'mdi-file-document-edit-outline' },
			{ id: 'comments', label: 'Opmerkingen',icon:'mdi-chat-outline' },
			{ id: 'url', label: 'Url',icon:'mdi-link-variant' },
			{ id: 'mails', label: 'Mail log',icon:'mdi-email-search-outline' }
		],
		payments: [
			{ id: 'deposit', label: 'Huurwaarborg' },
			{ id: 'rent', label: 'Huur' },
			{ id: 'egwi', label: 'Egwi' },
			{ id: 'studentTax', label: 'Studenten taks' },
			{ id: 'inventory', label: 'Plaatsbeschrijving' }
		]
	}),
	watch: {
		options: {
			handler () {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	mounted() {
		const config = { headers: { 'Content-Type': 'application/json' } };
    api
      .get(`${this.basicUrl}/mail-templates?minimal=true`, config)
      .then(({ data }) => {
        const { data: templates } = data;

        this.mailTemplates = Array.isArray(templates)
          ? templates
          : [];
      });
	},
	computed: {
		...mapState({
			tenants: state => state.tenants.tenants,
		}),
		getHeaders() {
			return this.extended ? [
        { text: 'Status', value: 'colorStatus', sortable: false}, // contract detail
				{ text: 'Start', value: 'startDate', sortable: true },
				{ text: 'Einde', value: 'endDate' , sortable: true},
				{ text: 'Project', value: 'project.name', sortable: false },
				{ text: 'Nummer', value: 'unitName', sortable: true },
				{ text: 'Naam', value: 'tenant.fullName', sortable: false },
				{ text: 'WB', value: 'rentalDeposit.status',sortable: false },
				{ text: 'Huur', value: 'rent.status' ,sortable: false},
				{ text: 'EGWI', value: 'egwi.status' ,sortable: false},
				{ text: 'Taks', value: 'studentTax.status' ,sortable: false},
				{ text: 'PB', value: 'plaatsbeschrijving.status' ,sortable: false},
        		{ text: 'Extras', value: 'extras.status' ,sortable: false},
        		{ text: 'Inschrijving', value: 'registrationDocument' ,sortable: false},
				{ text: 'Paspoort', value: 'imgFront',sortable: false },
				{ text: 'Getekend', value: 'signed', sortable: false },
				{ text: 'Getekend op', value: 'signedAtDate', sortable: true },
				{ text: 'Type', value: 'type', sortable: true }
			] : [
				{ text: 'TenantId', value: 'tenant.tenantId', sortable: false },
				{ text: 'Start', value: 'startDate', sortable: false },
				{ text: 'Einde', value: 'endDate', sortable: false },
				{ text: 'Project', value: 'project.name', sortable: false },
				{ text: 'Nummer', value: 'unit.name', sortable: false },
				{ text: 'Taal', value: 'tenant.language', sortable: false },
				{ text: 'Naam', value: 'tenant.fullName', sortable: false },
				{ text: 'Email', value: 'tenant.email' , sortable: false},
				{ text: 'Mails', value: 'maillog' , sortable: false},
				{ text: '-', sortable: false },
			];
		},
		...mapGetters({
			getRole: GETTERS.ROLE,
			getRights: GETTERS.RIGHTS
		}),
		canEdit() {
			return this.getRights.FOLLOW_UP_ENTIES.toLowerCase()=== "edit"
		},
		canDownload() {
			return this.getRights.RENTAL_DIARY_DOWNLOAD.toLowerCase() === "edit" || this.getRights.RENTAL_DIARY_DOWNLOAD.toLowerCase() === "view"
		}
	},
	methods: {
		...mapActions({
			getTenants: ACTIONS.GET_ALL_TENANTS,
			updateTenantPassportCheck: ACTIONS.SET_PASSPORT_CHECK,
			updateTenantPaymentCheck: ACTIONS.SET_PAYMENT_CHECK,
			uploadData: ACTIONS.UPLOAD_FILE,
			removeTenant: ACTIONS.DELETE_TENANT,
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
			mailAll: ACTIONS.MAIL_ALL,
			mailTenantsSelection: ACTIONS.MAIL_TENANTS_SELECTION,
			mailEntriesSelection: ACTIONS.MAIL_ENTRIES_SELECTION,
			mailPerson: ACTIONS.MAIL_PERSON,
			uploadTenant: ACTIONS.UPLOAD_TENANT,
      downloadRentalDiary: ACTIONS.DOWNLOAD_RENTAL_DIARY,
      downloadEntries : ACTIONS.DOWNLOAD_ENTRIES
		}),
    setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;
      this.getDataFromApi();
    },
		menuItemClicked(menuItemId,contract) {
			switch (menuItemId) {
				case "contract":
					this.createContractDialogItem = contract;
					this.createContractDialog = true;
					this.editContract = true,
					this.extension = true,
					this.checkToUpdate = contract
				break;

				case "comments":
					this.commentDialogItem = {_id: contract._id, item:contract};
					this.commentDialog = true;
					break;

				case "url":
					this.goToPaymentPage(contract)
					break;

				case "mails":
					this.MaillogDialogItem = {logs: contract.tenant['mailLog'], email: contract.tenant['fullName']};
					this.MaillogDialog = true;
					break;

				case "delete":
					this.confirmDeleteDialogItem = contract;
					this.confirmDeleteDialog = true
					break;

				default:
					break;
			}
		},
		updateRemarks(val) {
			let data = {
				"text": `${val.comment}`
			}

			api.post(`${this.basicUrl}/contracts/${val._id}/comment`, data).then(() => {
				this.makeToast({
					message: `Comment werd succesvol opgeslagen`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
		deleteRemark(val) {
			api.delete(`${this.basicUrl}/contracts/${val.contractId}/comment/${val.remarkId}`).then(() => {
				this.makeToast({
					message: `Comment werd succesvol verwijderd`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})

			this.commentDialogItem = null;
			this.commentDialog = false;
		},
		toggleDisableButton() {
		  this.disableMail = !this.selectedMailTemplate;
		},
		mailSelected() {
      let filter = {};
      const filterPairs = this.getAllTenants.split('&');
      filterPairs.forEach(pair=>{
        const p = pair.split('=');
        filter[p[0]] = p[1];
      });
			const mailTemplate = this.selectedMailTemplate;

			let data = {
				"templateId" : mailTemplate,
				filter: this.filterParams
			}

			if (this.extended) {
				return this.mailEntriesSelection(data).then(() => {
					this.makeToast({
						message: `Mail werd succesvol verzonden`,
						type: 'success',
					})}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				})
			} else {
				this.mailTenantsSelection(data).then(() => {
					this.makeToast({
						message: `Mail werd succesvol verzonden`,
						type: 'success',
					})}, (error) => {
					this.makeToast({ message: error, type: 'error' });
				})
			}
		},
		mailItem(item) {
			let data = {
				"subject": `${item.brand['name'].toUpperCase()} | The road to ${item.project['name']}`,
				"url": `${item.extUrl}`,
			}

			api.post(`${this.basicUrl}/mailing/tenants/${item.tenant['_id']}`, data).then(() => {
				this.makeToast({
					message: `E-mail succesvol verzonden`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
    getEntriesList(){
      this.dlEntries = true;
			const params = this.filterParams;
      this.downloadEntries({params}).then(data=>{
        let url = '';

				if (window.URL !== undefined && typeof window.URL === "function") {
					url = window.URL.createObjectURL(new Blob([data]));
				} else {
					const base64 = utf8_to_b64(data);
					url = `data:application/pdf;base64,${base64}`;
				}

				const element = document.createElement('a');

				element.href = url;
				element.setAttribute('download', 'entries-list.csv');
				document.body.appendChild(element);

				element.click();
				element.remove();

         this.dlEntries = false;
      }).catch(error=>{
        console.error(error);
         this.dlEntries = false;
         	this.makeToast({ message: error, type: 'error' });
      });
    },
    getRentalDiaryData() {
      this.dlRentalDiaryLoading = true;
			const params = this.filterParams;

      this.downloadRentalDiary({params}).then(data=>{
        let url = '';

				if (window.URL !== undefined && typeof window.URL === "function") {
					url = window.URL.createObjectURL(new Blob([data]));
				} else {
					const base64 = utf8_to_b64(data);
					url = `data:application/pdf;base64,${base64}`;
				}

				const element = document.createElement('a');

				element.href = url;
				element.setAttribute('download', 'rental-diary.csv');
				document.body.appendChild(element);

				element.click();
				element.remove();

         this.dlRentalDiaryLoading = false;
      }).catch(error=>{
        console.error(error);
         this.dlRentalDiaryLoading = false;
         	this.makeToast({ message: error, type: 'error' });
      });
		},
		uploadFile() {
			this.loading = true;
			this.makeToast({
				message: `even geduld we updaten de huurders`,
				type: 'success',
			});
			this.uploadData(this.file).then(() => {
				this.makeToast({
					message: `huurders succesvol opgeslagen`,
					type: 'success',
				});
				this.loading = false;
			}, (error) => {
				this.loading = false;
				this.makeToast({ message: error, type: 'error' });
			});
		},
		goToPaymentPage(item) {
			window.open(`${item.extUrl}`, '_blank');
		},
		updateTenant(val) {
			let data = {
				"checked": val.passportValid,
        "contractId": val.contractId,
        "brandName": val.brandName
			}

			api.patch(`${this.basicUrl}/tenants/${val.tenantID}/passport-status`, data).then((response) => {
				this.checkToUpdate['passportStatus'] = response.data.data.passportStatus;

				this.makeToast({
					message: `Paspoort controle aangepast`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})

			this.dialogItem = null;
			this.dialog = false;




		},
		updateContractDocument(value){
			const {documentType,documentStatus,contractId,documentId} = value;

			let data = {
				documentType,
				isVerified:documentStatus
			};
			api.patch(`${this.basicUrl}/contracts/${contractId}/documents/${documentId}`, data)

				.then((resp)=>{
								//registrationDocument
					
					if(resp.status == 200){

						this.checkToUpdate.registrationDocument = documentStatus ? 'APPROVED' : 'NOT_PRESENT';
					}
					this.makeToast({
						message: `document status is gewijzigd`,
						type: 'success',
					});
				},(error)=>{
					this.makeToast({ message: error, type: 'error' });
				});

			this.documentDialogItem = null;
			this.documentDialog = false;
		},
		updateTenantPayment(val) {
			let updatePayment = (val.payment)
			let data = {
				[updatePayment] : {
					"status": val.status,
					"documentId": val.documentId,
          "tenantId":val.tenantId,
          "brandName":val.brandName
				}
			}

			api.patch(`${this.basicUrl}/fees/${val._id}/follow-up`, data).then((response) => {
				let updateCheck = response.data.data.feeStats[0];
				this.checkToUpdate[val.payment] = updateCheck[val.payment]

				this.makeToast({
					message: `betaling controle aangepast`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})

			this.paymentDialogItem = null;
			this.paymentDialog = false;
		},
    closeContractDetail({hasChanges,contractId}){
      this.createContractDialog = false;
    },
		createContract(val,isLoading) {
      const contractId = val.contractID;
      this.isSavingContract = isLoading;

			if (val.editContract) {
				if (val.resetEcurring) {
					this.contractData = {
						overrideEcurringSubscriptions: [...val.overrideEcurringSubscriptions]
					}
				} else {
					this.contractData = {
						...val
					}
				}

				api.patch(`${this.basicUrl}/contracts/${val.contractID}`, this.contractData).then(() => {
          this.isSavingContract = false;
					this.makeToast({
						message: `Contract werd succesvol geüpdatet`,
						type: 'success',
					});

					this.getDataFromApi();
					this.createContractDialogItem = null;
					this.createContractDialog = false;
				}, () => {
          this.isSavingContract = false;
					this.makeToast({ message: 'Er liep iets mis bij het aanmaken van dit contract', type: 'error' });
				})
			} else {
				let data = {
					unitId: val.unitId,
					tenantId: val.tenantId,
					startDate: val.startDate,
					endDate: val.endDate,
					price: val.price,
					comment: val.comment
				}

				api.post(`${this.basicUrl}/contracts`, data).then(() => {
          this.isSavingContract = false;
					this.makeToast({
						message: `Contract werd succesvol aangemaakt`,
						type: 'success',
					});
					this.createContractDialogItem = null;
					this.createContractDialog = false;
				}, (error) => {
          console.log(error);
          this.isSavingContract = false;
					this.makeToast({ message: 'Er liep iets mis bij het aanmaken van dit contract', type: 'error' });
				})
			}
		},
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		showBullet(unit, year, payment) {
			if (!unit || !unit.prices) return false;
			let prices = unit.prices.find(p => p.year === year)
			return prices && prices[payment] && prices[payment].status;
		},


    deleteContract(contract) {
			api.delete(`${this.basicUrl}/contracts/${contract._id}`).then(() => {
				this.makeToast({
					message: `Contract succesvol verwijderd`,
					type: 'success',
				});
				this.confirmDeleteDialog = false;
				this.confirmDeleteDialogItem = null;
				this.getDataFromApi();
			}, (error) => {
				this.makeToast({ message:'Er liep iets mis bij het verwijderen van het contract', type: 'error' });
			})
		},
		getDataFromApi () {
			this.loading = true
			this.tenantsCall().then(data => {
				this.tenantsV2 = data.items
				this.totalTenants = data.total
				this.loading = false
			})
		},
		tenantsCall () {
			const config = { headers: { 'Content-Type': 'application/json' } };

			return new Promise((resolve) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let sorting = null;
				if (sortBy.length === 1 && sortDesc.length === 1) {
          sorting = sortDesc[0] ? `${sortBy[0]}:1` : `${sortBy[0]}:-1`;
				}

        const params = {
          skip: page-1,
          limit: itemsPerPage,
          sortBy: sorting,
          ... this.filterParams
        };
        const endpoint = this.extended ? `${this.basicUrl}/contracts/follow-up/entries` : `${this.basicUrl}/contracts/tenants`;

				api.get(endpoint, {config,params})
				.then(function (response) {
					let items = response.data.data;
					const total = response.data.totalCount
					resolve({items,total});
				})
			})
		},
	},
};
</script>

<style lang="scss" scoped>

@import 'src/styles/global-color-codes.scss';
@import 'src/styles/global-filter.scss';

.container {
	margin-left: 250px;
	margin-right: 250px;
	max-width: calc(100vw - 300px);
}

.form-title {
	display: flex;
}

tr {
	background-color: #ffffff;
}

tr:nth-child(odd) {
	background-color: #f6f6f6;
}

.v-card {
	width: 100% !important;
}



</style>
