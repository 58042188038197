<template>
  <v-card>
    <v-card-title class="px-12 pt-8 pb-0">
      <h3 v-if="isEdit">Mail Template aanpassen</h3>
      <h3 v-else>Mail Template aanmaken</h3>
    </v-card-title>
    <v-card-text>
       <v-layout row wrap>
          <v-flex class="pa-8">
          <v-form ref="form">
            <v-row >
            <v-col cols="12" sm="12">
                <v-text-field
                  hide-details
                  v-model="name"
                  label="Naam (koppeling met mail-chimp)"
                ></v-text-field>
              </v-col>
            </v-row>
            <h3 class="subtitle">vertalingen</h3>
            <v-row class="translation-row">
              <v-col cols="2" sm="2" class="label">nederlands:</v-col>
              <v-col cols="10" sm="10">
                 <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="titles.nl"
                ></v-text-field>
              </v-col>
            </v-row>
             <v-row class="translation-row">
              <v-col cols="2" sm="2" class="label">frans:</v-col>
                <v-col cols="10" sm="10">
                 <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="titles.fr"
                ></v-text-field>
              </v-col>
            </v-row>
             <v-row class="translation-row">
              <v-col cols="2" sm="2" class="label">engels:</v-col>
                <v-col cols="10" sm="10">
                 <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="titles.en"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          </v-flex>
       </v-layout>
    </v-card-text>
    <v-card-actions class="mb-2">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('close')">
				Annuleren
			</v-btn>
      <v-btn color="blue darken-1" text @click="save" :disabled="!isComplete" v-if="canEdit">
        <span v-if="isEdit">Mail Template aanpassen</span>
        <span v-else>Mail Template aanmaken</span>
      </v-btn>

    </v-card-actions>
  </v-card>
</template>
<script>
  import { isEmpty } from 'ramda';
    export default {
      name:'mail-template-dialog',
      props: {
        mailTemplate: {
          type: Object,
        },
        isEdit: {
          type: Boolean,
        },
        canEdit: {
          type: Boolean
        }
		  },

      data: ()=>({
        _id:null,
        name:'',
        type: 'campaign',
        titles:{
          en:'',
          nl:'',
          fr:''
        }
      }),

      mounted() {
        if(this.isEdit){
          this.mapForView(this.mailTemplate);
        }
      },

      computed:{
        isComplete(){
          return this.name && this.titles.en && this.titles.fr && this.titles.nl;
        }
      },

      methods:{
        mapForView:function(data){
          if (isEmpty(data)) {
            return [];
          }
          //
          this._id = data._id;
          this.name = data.name;
          this.titles = {
            en:data.en.title,
            fr:data.fr.title,
            nl:data.nl.title
          },
          this.type = data.type
        },
        mapForSave:function(){
          return {
            _id:this._id,
            name:this.name,
            type:this.type,
            en:{title: this.titles.en},
            nl:{title: this.titles.nl},
            fr:{title: this.titles.fr},

          }
        },
        save: function(){
          this.$emit('save',{...this.mapForSave()},{isEdit:this.isEdit});
        }
      }
    }
</script>
<style lang="scss" scoped>
.subtitle{
  padding: 24px 12px 6px 0;
}

.translation-row{
  align-items: baseline;

  .label{
    text-align: right;
    padding-right: 0 !important;
  }
}
</style>
