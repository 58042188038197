import api from '../../../services/backendApi';

const companiesUrl = '/companies';
const paymentsUrl = '/payments';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
	getCompanies(filter) {
		return api.get(`${companiesUrl}`, { filter }, config);
	},
	getCompanyDetail(id) {
		return api.get(`${companiesUrl}/${id}`, config);
	},
	updateCompany(company) {
		return api.patch(`${companiesUrl}/${company._id}`, {company}, config);
	},
	createCompany(company) {
		return api.post(`${companiesUrl}`, {company}, config);
	},
	getSubscriptionPlans() {
		return api.get(`${paymentsUrl}/ecurring`, config);
	},
	deleteCompany(company) {
		return api.delete(`${companiesUrl}/company/${company._id}`,  config);
	},
	uploadData(file) {
		const formData = new FormData();
		formData.append('file', file, 'data.csv');
		return api.post(`${companiesUrl}/uploadData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	},
};
