import { ACTIONS, MUTATIONS } from './permissions.constants';
import permissionsService from './permissions.service';

const payment = {
	state: {
		loading: false,
		permissions: [],
		resets: []
	},
	actions: {
		[ACTIONS.GET_ROLES]({ commit, dispatch }) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			
      return new Promise((resolve, reject) => {
				permissionsService.getRoles()
					.then((result) => {
						commit(MUTATIONS.SET_ALL_ROLES, result.data.rows);
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
					.finally(() => {
						// dispatch(ACTIONS.SET_ALL_RESETS);
						commit(MUTATIONS.SET_IS_LOADING, false);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_ALL_ROLES](state, permissions) {
			state.permissions = permissions;
		},
		[MUTATIONS.SET_ALL_RESETS](state, resets) {
			state.resets = resets;
		},
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		},
	},
};


export default payment;
