<template>
	<v-container grid-list-md text-xs-center class="container">
		<v-flex md12 xs12 class="ma-5">
			<v-card class="card pa-4">
				<div class="header">
					<h1 class="secondary-text text-lg-left form-title">
						Huurders
						<v-spacer></v-spacer>
					</h1>
					<h4 class="text-sm-left grey--text">Overzicht huurders </h4>
				</div>
				<entry-list :extended=false></entry-list>
			</v-card>
		</v-flex>
	</v-container>
</template>

<script>
import EntryList from "./entries-list";

export default {
	components: { EntryList },
};
</script>
