import axios from 'axios';

const api = axios
	.create({
		baseURL: process.env.VUE_APP_API_URL,
		handlerEnabled: false,
		headers: {'Referrer-Policy': 'no-referrer'},
		crossorigin:true
	});


api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token');

		if (token) {
			// eslint-disable-next-line no-param-reassign
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},

	error => Promise.reject(error));


export default api;
