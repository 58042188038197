import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#C80707',
				secondary: '#32A0DA',
				accent: '#F3F3F3',
				success: '#9BC631',
				error: '#b71c1c',
				upkot: '#e63328',
				upliving: '#40b6a8',
			},
		},
	},
});
