/**
 * Validate a given IBAN string
 * @param {string} iban 
 * @returns {boolean} Whether the given IBAN is valid or not
 */
export function validateIbanChecksum(iban) {
    const ibanStripped = (iban
        ? iban.replace(/[^A-Z0-9]+/gi, '')
        : '').toUpperCase();
        
    const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);

    if (!m) return false;

    const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch) {
        return (ch.charCodeAt(0) - 55); //replace upper-case characters by numbers 10 to 35
    });

    // The resulting number would be too long for javascript to handle without losing precision.
    // So the trick is to chop the string up in smaller parts.
    const mod97 = numbericed
        .match(/\d{1,7}/g)
        .reduce(function (total, curr) {
            return Number(total + curr) % 97
        }, '');

    return (mod97 === 1);
}