<template>
  <div class="mb-4">
<!-- overview extra payments , already setup -->
    <v-row class="extra-payment-item align-center" v-for="(payment,index) in extraPayments" :key="payment._id">
      <v-col class="extra-payment-status">
        <payment-status :editable='true' :documents="documents" :paymentItem='payment' @save='updateExtraPaymentStatus($event,payment._id,index)'></payment-status>
      </v-col>
      <!-- <v-col class='text-truncate' title='Eenmalige betaling via mollie'>Eenmalige betaling via mollie</v-col> -->
      <v-col class='text-truncate' sm="3" md="3" :title='payment.title'>{{payment.title}}</v-col>
      <v-col class='text-truncate' :title='payment.payToName'>{{payment.payToName}}</v-col>
      <v-col class='text-truncate' :title='payment.payToBankAccount'>{{payment.payToBankAccount}}</v-col>
      <v-col sm="1" md="1">{{payment.amount}}</v-col>
      <v-col sm="1" md="1" class="d-flex justify-end align-center">
        <v-icon color="grey"
          @click="confirmDeleteDialog = true, confirmDeleteDialogItem = payment, extraPaymentIndex = index"  >
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
    <!-- button to add extra payment -->
    <v-btn
      class="my-2"
      outlined
      color="grey"
      @click="addOneTimePayment"
    >
      Éénmalige betaling toevoegen
    </v-btn>

<!-- overview new extrapayments , -->
    <v-row v-for="(payment, index) in newExtraPayments" :key="payment.id">
      <v-col cols="12" sm="12">
        <v-card class="pa-3 mt-4">
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-select
                    outlined
                    v-model="payment.paymentMethod"
                    label="Betaalkeuze"
                    :items="paymentTypes"
                    item-value="id"
                    item-text="label"
                    required
                    hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="d-flex justify-end align-center">
                <v-icon
                  class="mx-2"
                  color="grey"
                  @click="newExtraPayments.splice(index, 1);"
                >
                  mdi-delete
                </v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="23">
                <v-text-field
                    outlined
                    v-model="payment.title"
                    label="Titel"
                    type="text"
                    required
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    outlined
                    v-model="payment.ownerCompanyId"
                    @change="onCompanyChange(payment.ownerCompanyId, payment)"
                    label="Company / Eigenaar"
                    :items="getCompanies(payment.paymentMethod)"
                    item-value="_id"
                    item-text="name"
                    required
                    hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    v-model="payment.amount"
                    label="Bedrag"
                    type="number"
                    required
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    :disabled=true
                    v-model="payment.payToName"
                    label="Naam rekeninghouder"
                    required
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    outlined
                    :disabled=true
                    v-model="payment.payToBankAccount"
                    label="Rekeningnummer"
                    required
                    hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="12" class="py-0 mt-3">
								<v-textarea
									auto-grow
									rows="5"
                  row-height="22"
									v-model="payment.info"
									label="Extra informatie over de éénmalige betaling"
									class="pt-0"
								></v-textarea>
							</v-col> -->
            </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDeleteDialog" max-width="400px">
      <confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
                      @cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
                      @delete="deleteExtraPayment"></confirm-delete>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../services/backendApi';
import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
import { mapActions } from 'vuex'
import ConfirmDelete from '@components/dialog/confirm-delete';
import PaymentStatus from '@components/payment-status/payment-status';
import PAYMENT_STATUS from '../../../../components/payment-status/payment-status.enum';

export default {
  components: { ConfirmDelete,PaymentStatus },
  name: "createPayment",
  data: vm => ({
    documents: null,
    extraPaymentIndex: null,
    confirmDeleteDialog: false,
		confirmDeleteDialogItem: null,
    basicUrl: process.env.VUE_APP_API_URL,
    extraPayments: [],
    newExtraPayments:[],
    paymentTypes: [
      {id: 'onetimepayment', label: 'Eenmalige betaling via Mollie'},
      {id: 'onetimetask', label: 'Eenmalige betaling'},
    ],
    companies: [],
  }),
  props: {
    item: {
      type: Object,
    }
  },
  mounted() {
    this.documents = this.item.documents;

    const config = { headers: { 'Content-Type': 'application/json' } };
    api
      .get(`${this.basicUrl}/owners/companies-owners?minimal=true`, config)
      .then(response => (this.companies = response.data.data))
      this.extraPayments = this.item.extraPayments || [];

    // this.item.extraPayments ? this.payments = [...this.item.extraPayments] : this.payments = this.payments;
  },
  watch: {
    newExtraPayments: {
      handler () {
        this.$emit('save', this.newExtraPayments)
			},
      deep: true
    }
  },
  computed: {
    mollieCompanies() {
			return this.companies.filter(e => !!e.key)
		},
    companiesWithAccountDetails() {
			return this.companies.filter(e => e.bankAccount && e.name)
		},
  },

  methods: {
    ...mapActions({
      makeToast: TOAST_ACTIONS.MAKE_TOAST,
    }),
    updateExtraPaymentStatus(val,extraPaymentId,extraPaymentsArrayIndex){
      const {status} = val;
      const data = {status: status == PAYMENT_STATUS.PAID};
      api
        .patch(`${this.basicUrl}/fees/${this.item.fees._id}/extra-payments/${extraPaymentId}`,data)
        .then((response)=>{
          this.extraPayments[extraPaymentsArrayIndex].checked = this.extraPayments[extraPaymentsArrayIndex].status = data.status;
          this.$emit('save', this.newExtraPayments);
        },(error)=>{
            this.makeToast({ message: error, type: 'error' });
        });
    },
    deleteExtraPayment(val) {
      api
        .delete(`${this.basicUrl}/fees/${this.item.fees._id}/extra-payments/${val._id}`)
        .then(response => (
          this.makeToast({ message: `Extra betaling werd succesvol verwijderd`, type: 'success' }),
          this.confirmDeleteDialog = false,
          this.confirmDeleteDialogItem = null,
          this.$emit('save', this.newExtraPayments)
      ))

      const newArr = this.extraPayments.splice(this.extraPaymentIndex,1);
  console.log(newArr);
      return newArr
    },
    addOneTimePayment() {
      let newObject = {
        title: null,
        paymentMethod: 'onetimepayment',
        ownerCompanyId: null,
        payToBankAccount: null,
        payToName: null,
        amount: null,
        info: null,
      };

      this.newExtraPayments.unshift(newObject);
		},
    getCompanies(paymentType) {
			switch (paymentType) {
				case 'onetimepayment':
					return this.mollieCompanies;
				default:
					return this.companiesWithAccountDetails
			}
		},
    onCompanyChange(companyId, payment) {
			let company = this.companies.find(c => c._id === companyId);

			payment.payToBankAccount = company.bankAccount;
			payment.payToName = company.name;
			payment.companyId = company._id;
		}
  }
}
</script>
<style>
.extra-payment-item{
  margin-top:0 !important;
  margin-bottom: 0 !important;
}
.extra-payment-item:nth-child(odd){
  background-color: #f6f6f6;
}

.extra-payment-status{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-grow:0;
}
</style>
