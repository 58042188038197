// https://developer.mozilla.org/en-US/docs/Glossary/Base64

/**
 * @param {string} str any string
 * @returns {string} base64 encoded data
 */
export function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

/**
 * @param {string} str base64 encoded data
 * @returns {string} the original string
 */
export function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

  // Usage:
//   utf8_to_b64('✓ à la mode'); // "4pyTIMOgIGxhIG1vZGU="
//   b64_to_utf8('4pyTIMOgIGxhIG1vZGU='); // "✓ à la mode"