import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/config/modules';
import { store as filterStore } from '@components/data-filter/store/filter.store';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,
  mutations: {
    addFilter(state, payload) {
      const { state: filterState, actions, mutations } = filterStore;
      if (!store.hasModule(payload)) {
        store.registerModule(payload, { state: filterState, actions, mutations, namespaced: true });
        console.log(`CREATED STORE: ${payload}`);
      }
    }
  }
});

const entries = Object.entries(modules);
for (let i = 0; i < entries.length; i++) {
  const moduleDefinition = entries[i][1];
  store.registerModule(moduleDefinition.MODULE_NAME, moduleDefinition.store);
}

export default store;

