<template>
	<div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title mb-4">Overzicht Rollen & Rechten</h1>
					</div>

					<v-card flat :elevation="0">
						<v-tabs vertical>
							<v-tab v-for="role in roles" :key="role._id">
								{{role.name}}
							</v-tab>

							<v-tab-item class="pl-10" v-for="role in roles" :key="role._id">
								<v-card flat>
									<v-card-text class="pt-0 d-flex">
										<v-simple-table>
											<template v-slot:default>
												<tbody>
													<tr v-for="(item, item_key) in role.rights" :key="item_key">
														<td>{{ tableText(item_key) }}</td>

														<td class="d-flex align-center">
															<v-icon v-if="item==='EDIT'">
																mdi-pencil-box-outline
															</v-icon>
															<v-icon v-else-if="item==='VIEW'">
																mdi-eye
															</v-icon>
															<v-icon v-else>
																mdi-cancel
															</v-icon>
															<span class="ml-1">{{ item }}</span>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card-text>
								</v-card>
							</v-tab-item>
						</v-tabs>
					</v-card>

				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
	import { ACTIONS } from '../permissions.constants.js';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
  import { GETTERS } from '@/account/account.constants';
  import { Roles } from '@/account/account.store';

	export default {
		data: () => ({
			roles: [],
			roleTexts: [
				{ text: 'Opvolging intredes', value: 'FOLLOW_UP_ENTIES' },
				{ text: 'Opvolging rent', value: 'FOLLOW_UP_RENT' },
				{ text: 'Opvolging blijvers', value: 'FOLLOW_UP_STAYERS' },
				{ text: 'Projecten info', value: 'PROJECTS_INFO' },
				{ text: 'Rollen en rechten', value: 'ROLES_LIST' },
				{ text: 'Mail templates', value: 'SETTINGS_MAIL_TEMPLATES' },
				{ text: 'Instellingen Mollie', value: 'SETTINGS_MOLLIE' },
				{ text: 'Settings gebruikers', value: 'SETTINGS_USERS' },
				{ text: 'Informatie units', value: 'UNITS_INFO' },
				{ text: 'Units prijzen', value: 'UNITS_PRICES' },
				{ text: 'Huurdagboek data download', value: 'RENTAL_DIARY_DOWNLOAD' }
			]
		}),
		watch: {

		},
		mounted() {
			this.getRoles().then((res) => {
				console.log(res.data)
				this.roles = [...res.data]
			}, (error) => {
				console.log('ROLES NOT PRESENT');
			})
		},
		computed: {
			...mapState({

			}),
			...mapGetters({
				role: GETTERS.ROLE
			}),
		},
		methods: {
			...mapActions({
				getRoles: ACTIONS.GET_ROLES,
			}),
			tableText(val) {
				let roleText = this.roleTexts.find(obj => obj.value === val);
				return !roleText ? `${val}` : `${roleText.text}`
			}
		},
	};
</script>

<style lang="scss" scoped>
	.v-data-table {
		max-width: 70% !important;
		width: 27.5rem !important;
	}
</style>
