import { MAIL_TEMPLATE_NAME_KEY } from "../services/mail-template.service";

export const INITIAL_FILTERS = {
  skip: 0,
  limit: 10,
  textSearch: null,
  sortBy: null
}

export const MAIL_TEMPLATE_LIST_HEADERS = [
  {
    text: '',
    value: 'edit',
    sortable: false,
    width: '1rem',
  },
  {
    text: 'Naam',
    value: 'name',
    sortable: true,
  },
  {
    text: ' ',
    value: 'delete',
    sortable: false,
    width: '1rem',
  },
];

export const FILTER_SORT_OPTIONS = {
  [MAIL_TEMPLATE_LIST_HEADERS[1].value]: MAIL_TEMPLATE_NAME_KEY,
}
