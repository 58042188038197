import { isNil, isEmpty, update } from 'ramda';
import api from '@/services/backendApi';

/**
 * These filter keys are defined in constants so that they can be set in one place.
 * This eliminates the danger of typos and avoids complex search & replaces.
 *
 * These are used in the parseUnitsParams but also in ../list/mail-template-list.const.js
 */
export const MAIL_TEMPLATE_NAME_KEY = 'name';

/**
 * The baseUrl and config are the two shared parts of each request in the service.
 * That's why they are defined as constants, to avoid duplicate code.
 */
const baseUrl = `${process.env.VUE_APP_API_URL}/mail-templates`;
const config = {
  headers: {
    'Content-Type': 'application/json'
  },
};

const parseTemplateParams = (params) => {
  if (isNil(params) || isEmpty(params)) {
    return {
      skip: 0,
      limit: 10,
    };
  }

  return {
    ...params,
    skip: isNil(params.skip) ? 0 : params.skip,
    limit: isNil(params.limit) ? 10 : params.limit
  };
};

export const mailTemplateService = {
  getMailTemplates: (params) => {
    return api.get(
      `${baseUrl}`,
      {
        ...config,
        params: parseTemplateParams(params),
      },
    );
  },
  createMailTemplate: (val) => {
    return api.post(
      `${baseUrl}`,
      val,
      config,
    );
  },
  updateMailTemplate: (val) => {
    return api.patch(
      `${baseUrl}/${val._id}`,
      val,
      config,
    );
  },
  deleteMailTemplate: (val) => {
    return api.delete(
      `${baseUrl}/${val._id}`,
      config,
    );
  }
};
