import api from '../../../services/backendApi';

const tenantEntriesUrl = '/tenants';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getTenants(filter) {
    return api.get(`${tenantEntriesUrl}`, { filter }, config);
  },
  getTenantDetail(id) {
    return api.get(`${tenantEntriesUrl}/${id}`, config);
  },
  updateTenant(tenant) {
    tenant.prices.forEach(e => delete e['$id']);
    return api.patch(`${tenantEntriesUrl}/${tenant._id}`, { tenant }, config);
  },
  uploadData(file) {
    const formData = new FormData();
    formData.append('file', file, 'data.csv');
    return api.post(`/uploadData/omniboxx`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  updateTenantPassportCheck(tenant) {
    return api.patch(`${tenantEntriesUrl}/passport-check/${tenant._id}`, { passportValid: tenant.passportValid }, config);
  },
  updateTenantPaymentCheck(tenant) {
    return api.patch(`${tenantEntriesUrl}/payment-check/${tenant._id}`, { payment: tenant.payment, status: tenant.status }, config);
  },
  deleteTenant(tenant) {
    return api.delete(`${tenantEntriesUrl}/${tenant._id}`, config);
  },
  mailAll() {
    // return api.post(`/mailing/tenants/all`, config);
  },
  mailTenantsSelection(mailData) {
    return api.post(`/mailing/tenants/selection`, { ...mailData }, config);
  },
  mailEntriesSelection(mailData) {
    return api.post(`/mailing/follow-up-entries/selection`, { ...mailData }, config);
  },
  mailPerson(mails) {
    return api.post(`/mailing/tenants`, { mails }, config);
  },
  uploadTenant(tenant) {
    return api.post(`${tenantEntriesUrl}/create-tenant`, { tenantId: tenant.tenantId, email: tenant.email, emailParent: tenant.emailParent, endDate: tenant.endDate, startDate: tenant.startDate, name: tenant.name, year: tenant.year, unitId: tenant.unitId, language: tenant.language }, config);
  },
  updateContractRemarks(unitId, remark) {
    return api.patch(`/units/contract-remark/${unitId}`, remark, config);
  },
  removeContractRemarks(tenantId, remarkId) {
    return api.delete(`/units/contract-remark/${tenantId}/remark/${remarkId}`, config);
  },
  downloadRentalDiary(params) {
    return api.get(`/downloads/rental-diary`, { config, params })
  },
  downloadEntriesList(params) {
    return api.get(`/downloads/follow-up/entries`, { config, params })
  }
};
