import { mailTemplateService } from '../services/mail-template.service';
import { isEmpty, isNil } from 'ramda';

export const MAIL_TEMPLATES_MUTATIONS = {
  SET_MAIL_TEMPLATES: `mailTemplates/SET_MAIL_TEMPLATES`,
  SET_LOADING: `mailTemplates/SET_LOADING`,
  SET_ERROR: `mailTemplates/SET_ERROR`,
};

const mailTemplateState = {
  mailTemplates: [],
  totalCount: 0,
  filterData: null,
  isLoading: false,
  error: null,
};

export const mailTemplateMutations = {
  [MAIL_TEMPLATES_MUTATIONS.SET_MAIL_TEMPLATES](state, { mailTemplates, totalCount }) {
    state.mailTemplates = isNil(mailTemplates) || isEmpty(mailTemplates)
      ? []
      : mailTemplates;
    state.totalCount = totalCount;
    state.isLoading = false;
    state.error = null;
  },
  [MAIL_TEMPLATES_MUTATIONS.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MAIL_TEMPLATES_MUTATIONS.SET_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
}

export const MAIL_TEMPLATES_ACTIONS = {
  GET_MAIL_TEMPLATES: `mailTemplates/GET_MAIL_TEMPLATES`,
  CREATE_MAIL_TEMPLATE: `mailTemplates/CREATE_MAIL_TEMPLATE`,
  UPDATE_MAIL_TEMPLATE: `mailTemplates/UPDATE_MAIL_TEMPLATE`,
  DELETE_MAIL_TEMPLATE: `mailTemplates/DELETE_MAIL_TEMPLATE`,
};

const mailTemplateActions = {
  [MAIL_TEMPLATES_ACTIONS.GET_MAIL_TEMPLATES]({ commit }, params) {
    commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

    return mailTemplateService.getMailTemplates(params)
      .then(({ data }) => {
        const { data: mailTemplates, totalCount } = data;
        commit(MAIL_TEMPLATES_MUTATIONS.SET_MAIL_TEMPLATES, { mailTemplates, totalCount });
      })
      .catch((error) => { commit(MAIL_TEMPLATES_MUTATIONS.SET_ERROR, error) });
  },
  [MAIL_TEMPLATES_ACTIONS.CREATE_MAIL_TEMPLATE]({ commit }, { params, val }) {
    commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

    return mailTemplateService.createMailTemplate(val)
      .then(() => {
        commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

        return mailTemplateService.getMailTemplates(params);
      })
      .then(({ data }) => {
        const { data: mailTemplates, totalCount } = data;
        commit(MAIL_TEMPLATES_MUTATIONS.SET_MAIL_TEMPLATES, { mailTemplates, totalCount });
      })
      .catch((error) => { commit(MAIL_TEMPLATES_MUTATIONS.SET_ERROR, error) });
  },
  [MAIL_TEMPLATES_ACTIONS.UPDATE_MAIL_TEMPLATE]({ commit }, { params, val }) {
    commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

    return mailTemplateService.updateMailTemplate(val)
      .then(() => {
        commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

        return mailTemplateService.getMailTemplates(params);
      })
      .then(({ data }) => {
        const { data: mailTemplates, totalCount } = data;
        commit(MAIL_TEMPLATES_MUTATIONS.SET_MAIL_TEMPLATES, { mailTemplates, totalCount });
      })
      .catch((error) => { commit(MAIL_TEMPLATES_MUTATIONS.SET_ERROR, error) });
  },
  [MAIL_TEMPLATES_ACTIONS.DELETE_MAIL_TEMPLATE]({ commit }, { params, val }) {
    commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

    return mailTemplateService.deleteMailTemplate(val)
      .then(() => {
        commit(MAIL_TEMPLATES_MUTATIONS.SET_LOADING, true);

        return mailTemplateService.getMailTemplates(params);
      })
      .then(({ data }) => {
        const { data: mailTemplates, totalCount } = data;
        commit(MAIL_TEMPLATES_MUTATIONS.SET_MAIL_TEMPLATES, { mailTemplates, totalCount });
      })
      .catch((error) => { commit(MAIL_TEMPLATES_MUTATIONS.SET_ERROR, error) });
  },
};

export const store = {
  state: mailTemplateState,
  mutations: mailTemplateMutations,
  actions: mailTemplateActions,
};

